import React, { Component } from 'react';
import { ViewContext } from '../../context/ViewContext';
import classes from './NewsPage.module.scss';

class NewsPage extends Component {
    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    componentDidMount() {
        this.context.actions.setToolbarTitle('Neuigkeiten');
    }

    render() {
        return (
            <iframe
                name="Framename"
                title="vgDresden"
                src="https://www.vg-dresden.de/nachrichten.html"
                width="550"
                height="550"
                className={classes.vgIFrame}
            />
        );
    }
}

export default NewsPage;
