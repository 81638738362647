import React, {Component} from 'react';
import classes from './AboutPage.module.scss';
import Modal from '../../components/UI/Modal/Modal';
import {ViewContext} from '../../context/ViewContext';

interface IState {
    showLicense: boolean,
}

class AboutPage extends Component<{}, IState> {

    public readonly state: IState = {
        showLicense: false,
    };

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    componentDidMount() {
        this.context.actions.setToolbarTitle('Impressum');
    }

    showLicenseNotes = () => {
        this.setState({showLicense: true})
    };

    hideLicenseNotes = () => {
        this.setState({showLicense: false})
    };

    render() {
        return (
            <div className={classes.aboutPage}>
                <div>
                    <p><b>VG Verbrauchergemeinschaft für
                        umweltgerecht erzeugte Produkte eG</b></p>

                    <p>Büro im Umweltzentrum<br/>
                        Schützengasse 16<br/>
                        01067 Dresden</p>
                </div>
                <div>
                    <p>Tel.: 0351 – 497398-40 (Büro)<br/>

                        E-Mail: <a href='mailto:info@vg-dresden.de'
                                   target='blank'>info@vg-dresden.de</a><br/>
                        Internet: <a href='https://www.vg-dresden.de'
                                     target='blank'>www.vg-dresden.de</a></p>
                </div>
                <div>
                    <p>Aufsichtsratsvorsitzender: Ralf Werner</p>
                    <p>Vertretungsberechtigter Vorstand:<br/>
                        Barbara Rische, Jonathan Kirchner, Peter Jacobi</p>
                </div>
                <div>
                    <p>Registergericht: Amtsgericht Dresden - GnR 470<br/>
                        Kontrollstellennummer: DE-ÖKO-039, zertifiziert durch GfRS<br/>
                        Umsatzsteuer-ID: DE244068551</p>
                </div>
                <div>Das vollständige Impressum und die AGB gibt es <a
                    href='https://www.vg-dresden.de/impressum-agb' target='blank'>hier</a></div>
                <a href='https://www.vg-dresden.de/datenschutz'>Datenschutzhinweise</a>
                <a onClick={this.showLicenseNotes} href='#/'>Lizenzhinweise</a>
                <a href='https://gitlab.com/pyfox/vg-app/blob/master/THIRD-PARTY-LICENSES'
                   target='blank'>Drittanbieter-Lizenzen</a>
                <a href='https://gitlab.com/pyfox/vg-app/-/tree/master' target='blank'>Source Code</a>
                <Modal show={this.state.showLicense} modalClosed={this.hideLicenseNotes}>
                    <h3>Lizenzhinweise</h3>
                    <div>
                        <p>VG-App Copyright (C) 2019-2020 VG Verbrauchergemeinschaft für umweltgerecht erzeugte Produkte eG</p>

                        <p>This program is free software: you can redistribute it and/or modify
                            it under the terms of the GNU General Public License as published by
                            the Free Software Foundation, either version 3 of the License, or
                            (at your option) any later version.</p>

                        <p>This program is distributed in the hope that it will be useful,
                            but WITHOUT ANY WARRANTY; without even the implied warranty of
                            MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                            GNU General Public License for more details.</p>

                        <p>You should have received a copy of the GNU General Public License
                            along with this program. If not, see
                            <a href='http://www.gnu.org/licenses'
                               target='blank'> http://www.gnu.org/licenses</a>.
                        </p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default AboutPage;
