import React, {Fragment, ReactElement, useContext} from 'react';
import classes from './ListMenu.module.scss';
import Backdrop from '../UI/Backdrop/Backdrop';
import {ViewContext} from "../../context/ViewContext";

export interface IMenuItem {
    action: string;
    callback: () => void
}


export interface IProps {
    open: boolean,
    closed: () => void,
}

function ListMenu(props: IProps) {
    const context = useContext(ViewContext);
    let attachedClasses = [classes.listMenu, classes.close, 'listMenu'];
    if (props.open) {
        attachedClasses = [classes.listMenu, classes.open, 'listMenu'];
    }

    let listEntries: ReactElement[] = [];
    context.state.menuItems.forEach(item => {
        listEntries.push(<li key={item.action} onClick={() => {
            props.closed();
            item.callback();
        }}>{item.action}</li>)
    })

    return (
        <Fragment>
            <Backdrop transparent={true} show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ').trim()}>
                <ul>
                    {listEntries}
                </ul>
            </div>
        </Fragment>
    );
}

export default ListMenu;
