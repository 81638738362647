import React, {Component} from 'react';
import {withRouter, RouteComponentProps} from 'react-router';


import {DataManager} from '../../shared/DataManager';
import {GestureManager} from '../../shared/GestureManager';
import {getSearchParams} from '../../shared/utilities';

import {IChecklist, IPopupObject, IProduct, IProductFromDB} from '../../shared/interfaces';

import classes from './ShoppingListPage.module.scss';
import Checklist from '../../components/Checklist/Checklist';
import ChecklistCart from '../../components/ChecklistCart/ChecklistCart';
import InputLine from '../../components/InputLine/InputLine';
import SearchResults from '../../components/SearchResults/SearchResults';
import ProductInfo from '../../components/ProductInfo/ProductInfo';
import EditEntryForm from '../../components/EditEntryForm/EditEntryForm';
import Popup from '../../components/UI/Popup/Popup';
import ClearListHandler from './ClearListHandler/ClearListHandler';
import {SettingsManager} from '../../shared/SettingsManager';
import {ConfirmCreateNewEntryModal} from '../../components/ConfirmCreateNewEntryModal/ConfirmCreateNewEntryModal';
import {ViewContext} from '../../context/ViewContext';
import ShareListDialog from './ShareListDialog/ShareListDialog';
import ImportSharedListDialog from "./ImportSharedListDialog/ImportSharedListDialog";
import {EventManager} from "../../shared/EventManager";
import {APP_VERSION} from "../../shared/constants";

interface IEntryToCreateFromCheckListItemInfo {
    id: number;
    vgNum: number;
}

interface IState {
    checklist: IChecklist;
    searchResults: IProductFromDB[];
    showInfo: number | undefined;
    idWithInvalidVgNum?: number;
    popup: IPopupObject;
    deletedEntry?: IProduct;
    changeEntry?: IProduct;
    swipeCompleted: boolean;
    dragMode: boolean;
    showShareDialog: boolean;
    showImportSharedListDlg: boolean;
    highlightLongPress: boolean;
    clearList: boolean;
    entryToCreateFromChecklistItem?: IEntryToCreateFromCheckListItemInfo;
    shareId: string | null;
    shareKey: string | null;
}

class ShoppingListPage extends Component<RouteComponentProps, IState> {
    state: IState = {
        checklist: {},
        searchResults: [],
        showInfo: undefined,
        idWithInvalidVgNum: undefined, // Id des Checklisteneintrages mit invalider Nr-Eingabe
        popup: {
            show: false,
            argForButtonClicked: undefined,
            message: undefined,
            buttonText: undefined,
            buttonClicked: undefined
        },
        swipeCompleted: false,
        highlightLongPress: false,
        changeEntry: undefined,
        deletedEntry: undefined,
        showShareDialog: false,
        showImportSharedListDlg: false,
        dragMode: false,
        clearList: false,
        shareId: null,
        shareKey: null
    };
    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    currentTimeoutId?: number = undefined;
    swipeWidth: number = Number(
        getComputedStyle(document.body)
            .getPropertyValue('--swipeWidth')
            .slice(0, -2)
    );
    popupTimeoutDuration: number = 4000;
    settings = SettingsManager.getSettings();

    componentDidUpdate() {
        if (this.props.location.search) {
            this.handleSearchParams();
        }
    }

    clearList = () => {
        this.saveChecklistStateToLocalStorage()
        this.setState({clearList: true})
    }

    initDragMode = () => {
        this.props.history.push('/list?drag=true');
    }

    pushToSettingsPage = () => {
        this.props.history.push('/settings');
    }

    shareList = () => {
        this.props.history.push('/list?share=true')
    }

    componentDidMount() {
        this.context.actions.setToolbarTitle('Einkaufsliste');
        this.context.actions.setMenuItems([{action: 'Liste leeren', callback: this.clearList},
            {action: 'Einträge verschieben', callback: this.initDragMode},
            {action: 'Liste teilen', callback: this.shareList},
            {action: 'Liste importieren', callback: this.importSharedList},
            {action: 'Einstellungen', callback: this.pushToSettingsPage}
        ])
        window.addEventListener('beforeunload', this.saveChecklistStateToLocalStorage);
        window.addEventListener('error', this.removeEventListener);
        EventManager.subscribeToEvent('updatedUserListsData', this._onLocalStorageUpdated)
        this.syncStateWithLocalStorage();
        this.handleSearchParams();
        GestureManager.initialize();
        GestureManager.registerGesture('longPress', this.onLongPress);
        GestureManager.registerGesture('whileLongPress', this.highlightLongPress);
        GestureManager.registerGesture('swipeRight', this.onSwipeRight);
        GestureManager.registerGesture('swipeLeft', this.onSwipeLeft);
        GestureManager.registerGesture('dragEnd', this.onDragEnd);
        this._doVersionCheck();
    }

    _doVersionCheck = () => {
        if (SettingsManager.getSettings().settingsVersion !== APP_VERSION) {
            this.context.actions.showPopup('App wurde aktualisiert',
                'Changelog',
                () => this.props.history.push('/changelog')
            )
        }
        SettingsManager.mergeSettings({settingsVersion: APP_VERSION})
    }

    componentWillUnmount() {
        this.saveChecklistStateToLocalStorage();
        window.removeEventListener('beforeunload', this.saveChecklistStateToLocalStorage);
        window.removeEventListener('error', this.removeEventListener);
        EventManager.unsubscribeFromEvent('updatedUserListsData', this._onLocalStorageUpdated)
        this.context.actions.setMenuItems([])
        GestureManager.unInitialize();
        GestureManager.unregisterGesture('longPress', this.onLongPress);
        GestureManager.unregisterGesture('whileLongPress', this.highlightLongPress);
        GestureManager.unregisterGesture('swipeRight', this.onSwipeRight);
        GestureManager.unregisterGesture('swipeLeft', this.onSwipeLeft);
        GestureManager.unregisterGesture('dragEnd', this.onDragEnd);
    }

    _onLocalStorageUpdated = () => {
        this.syncStateWithLocalStorage()
    }

    saveChecklistStateToLocalStorage = () => {
        localStorage.setItem('checklist', JSON.stringify(this.state.checklist));
    };

    syncStateWithLocalStorage = () => {
        let storedChecklist = localStorage.getItem('checklist');
        if (storedChecklist) {
            this.setState({checklist: JSON.parse(storedChecklist)});
        }
    };

    handleSearchParams = () => {
        let {add, drag, share, shareId, hash} = getSearchParams(this.props.location.search);

        if (add) {
            // genutzt durch Scanner, gibt Ergebnis per SearchParam weiter
            this.addEntryHandler(add);
        }
        this.props.history.replace('/list');

        if (drag) {
            this.setState({dragMode: true});
        }

        if (share) {
            this.setState({showShareDialog: true})
        }

        if (shareId) {
            this.setState({shareId, shareKey: hash, showImportSharedListDlg: true})
        }
    };

    removeEventListener = () => {
        window.removeEventListener('beforeunload', this.saveChecklistStateToLocalStorage);
    };

    // Toggelt Checked-Status und damit die Zuordnung zu Einkaufs- oder Kassenliste
    toggleCheckHandler = (id: number) => {
        this.setState(previousState => {
            const updatedChecklist = {...previousState.checklist};
            let updatedItem;
            for (let key in updatedChecklist) {
                if (id === updatedChecklist[key].id) {
                    updatedItem = {...updatedChecklist[key]};
                    updatedItem.checked = !updatedItem.checked;
                    updatedChecklist[key] = updatedItem;
                }
            }

            DataManager.saveJSONToLocalStorage('checklist', updatedChecklist);
            return {
                checklist: updatedChecklist
            };
        });
    };

    // liefer ein neues Listeneintrags-Objekt mit minimalen Inhalten
    createNewEntry = (): IProduct => {
        return {
            title: '',
            checked: false,
            amount: 1,
            id: DataManager.getNextId(),
            hasVgNum: false,
            date: Date.now(),
            order: Object.keys(this.state.checklist).length
        };
    };

    // Prüft ob Nutzer VG-Nummer o. String eingegegben hat und ergänzt Infos wo möglich
    createEntryFromInput = (inputValue: string | number) => {
        let returnValue;

        // Nutzer hat Nummer eingegeben, lade entspr. Daten aus Storage
        if (!isNaN(Number(inputValue))) {
            let product = DataManager.getProductByVgNum(Number(inputValue));
            if (product.id !== -1) {
                const newEntry = this.createNewEntry();
                newEntry.title = product.title;
                newEntry.vgNum = product.vgNum;
                newEntry.country = DataManager.getCountryCodeById(product.countryID);
                newEntry.hasVgNum = true;
                newEntry.checked = this.settings.autoCheck;
                returnValue = newEntry;
            } else {
                this.setState({
                    popup: {
                        show: true,
                        message: `Kein Eintrag für ${inputValue} gefunden!\nEintrag erstellen?`,
                        buttonText: 'Erstellen',
                        buttonClicked: () => this.addItemHandler(Number(inputValue))
                    }
                });
                this.setPopupTimeout();
            }
        } else {
            // Nutzer hat Bezeichnung für neuen Eintrag eingegeben
            const newEntry = this.createNewEntry();
            //@ts-ignore
            newEntry.title = inputValue;

            returnValue = newEntry;
        }
        return returnValue;
    };

    // Neuer Eintrag wird aus Input erstellt und der Liste hinzugefügt
    addEntryHandler = (inputValue: string | number) => {
        let newEntry = this.createEntryFromInput(inputValue);
        if (newEntry) {
            this.saveEntryToList(newEntry);
        }
    };

    // Fügt neuen Eintrag der Checklist hinzu, triggert Popup
    saveEntryToList = (newEntry: IProduct) => {
        let popupObject = this.getPopupObject('add', newEntry);

        this.setState(previousState => {
            const checklist = {...previousState.checklist};
            let index = newEntry.id;
            checklist[index] = newEntry;
            this.cleanupChecklistOrder(checklist, true);  // Gecheckte Einträge neu sortieren
            this.cleanupChecklistOrder(checklist, false);  // Ungecheckte Einträge neu sortieren

            DataManager.saveJSONToLocalStorage('checklist', checklist);
            return {
                checklist,
                popup: popupObject
            };
        });

        this.setPopupTimeout();
    };

    // Erstellt neues Eintragsobjekt und toggelt Anzeige des Bearbeiten-Modals
    editNewItemEditHandler = (inputValue: string) => {
        let newEntry = this.createEntryFromInput(inputValue);
        this.setState({changeEntry: newEntry});
    };

    addItemHandler = (inputValue: string | number) => {
        // Nutzer hat Bezeichnung für neuen Eintrag eingegeben
        const newEntry = this.createNewEntry();
        if (typeof inputValue === 'string') {
            newEntry.title = inputValue;
        } else {
            newEntry.vgNum = inputValue;
        }
        newEntry.checked = newEntry.vgNum !== undefined && SettingsManager.getSettings().autoCheck;
        newEntry.country = '-';
        this.setState({changeEntry: newEntry});
    };

    // Prüft, ob bereits ein Eintrag unter der ID vorhanden ist,
    // wenn ja, wird dieser ersetzt, sonst ein neuer hinzugefügt
    updateItemHandler = (updatedEntry: IProduct) => {
        let popupObject: IPopupObject;

        this.setState(previousState => {
            const checklist = {...previousState.checklist};
            for (let index in checklist) {
                if (checklist[index].id === updatedEntry.id) {
                    // Eintrag schon vorhanden, soll ersetzt werden
                    checklist[index] = updatedEntry;
                    popupObject = this.getPopupObject('edit', updatedEntry);
                }
            }

            if (!popupObject) {
                // Eintrag war bisher nicht gespeichert (Edit-Button in InputLine), muss neu gespeichert werden
                let index = updatedEntry.id;
                checklist[index] = updatedEntry;
                popupObject = this.getPopupObject('add', updatedEntry);
            }
            this.cleanupChecklistOrder(checklist, true);  // Gecheckte Einträge neu sortieren
            this.cleanupChecklistOrder(checklist, false);  // Ungecheckte Einträge neu sortieren


            DataManager.saveJSONToLocalStorage('checklist', checklist);
            return {
                checklist,
                changeEntry: undefined,
                popup: popupObject
            };
        });
        this.setPopupTimeout();
    };

    // Toggelt Hide des Bearbeiten-Modals
    cancelEditHandler = () => {
        this.setState({changeEntry: undefined});
    };

    // Liefer ein Objekt mit den erforderlichen Infos
    getPopupObject = (
        mode: 'add' | 'edit' | 'delete' | 'hide',
        entry?: IProduct
    ): IPopupObject => {
        // noinspection JSUnusedLocalSymbols
        const popupObject = {
            show: mode !== 'hide',
            message: '',
            buttonText: '',
            buttonClicked: (id: number) => {
            },
            argForButtonClicked: entry ? entry!.id : undefined
        };

        switch (mode) {
            case 'add':
                popupObject.message = entry!.title + ' hinzugefügt!';
                popupObject.buttonText = 'Bearbeiten';
                popupObject.buttonClicked = this.editEntryHandler;
                popupObject.argForButtonClicked = entry!.id;
                break;
            case 'edit':
                popupObject.message = entry!.title + ' geändert!';
                break;
            case 'delete':
                popupObject.message = entry!.title + ' entfernt!';
                popupObject.buttonText = 'Rückgängig';
                popupObject.buttonClicked = this.restoreEntryHandler;
                break;
        }
        return popupObject;
    };

    // Löscht ggf. vorhandenes Timeout und setzt ein neues von 3s Dauer
    setPopupTimeout = () => {
        window.clearTimeout(this.currentTimeoutId);
        this.currentTimeoutId = window.setTimeout(
            () => this.setState({popup: {show: false}}),
            this.popupTimeoutDuration
        );
    };

    // Toggelt Anzeige des ProductInfo-Modals
    showInfoHandler = (vgNum: number) => {
        this.setState({showInfo: vgNum});
    };

    // Toggelt Hide des ProductInfo-Modals
    hideInfoHandler = () => {
        this.setState({showInfo: undefined});
    };

    hideCreateNewEntryModal = () => {
        this.setState({entryToCreateFromChecklistItem: undefined});
    };

    createDbEntryFromCheckListItem = () => {
        const changeEntry = this.getChecklistEntryById(
            this.state.entryToCreateFromChecklistItem!.id
        );
        changeEntry.vgNum = this.state.entryToCreateFromChecklistItem!.vgNum;
        this.setState({changeEntry, entryToCreateFromChecklistItem: undefined});
    };

    createDbEntryFromCheckListItemHandler = (id: number, vgNum: number) => {
        this.setState({entryToCreateFromChecklistItem: {id, vgNum}});
    };

    // Bei Nachträglicher Eingabe der VG-Nummer eines bereits erstellten Listeneintrages;
    // Ruft entsprechende Daten aus den DB ab und speichert damit ergänzten Eintrag
    changeVgNumHandler = (vgNum: number, title: string, id: number) => {
        let product = DataManager.getProductByVgNum(vgNum);
        if (product.id !== -1) {
            this.setState(previousState => {
                let updatedChecklist = {...previousState.checklist};
                let updatedChecklistEntry;
                for (let key in updatedChecklist) {
                    let item = updatedChecklist[key]
                    if (item.id === id && item.title === title) {
                        updatedChecklistEntry = {...item}
                        break
                    }
                }
                if (!updatedChecklistEntry) {
                    return {checklist: updatedChecklist}
                }

                if (updatedChecklistEntry.title !== product!.title) {
                    updatedChecklistEntry.title =
                        product!.title + '(' + updatedChecklistEntry.title + ')';
                }
                updatedChecklistEntry.vgNum = vgNum;
                updatedChecklistEntry.country = DataManager.getCountryCodeById(product!.countryID);
                updatedChecklistEntry.hasVgNum = true;

                if (this.settings.autoCheck) {
                    updatedChecklistEntry.checked = true;
                }

                updatedChecklist[id] = updatedChecklistEntry;
                DataManager.saveJSONToLocalStorage('checklist', updatedChecklist);
                if (this.settings.autoCheck) {
                    this.context.actions.showPopup(`${updatedChecklistEntry.title} in den Einkaufswagen verschoben!`)
                }
                return {checklist: updatedChecklist};
            });
        } else {
            this.createDbEntryFromCheckListItemHandler(id, vgNum);
        }
    };

    // Übergibt zu ändernden Eintrag an EditEntryForm u. triggert dessen Anzeige
    editEntryHandler = (id: number) => {
        if (!isNaN(id)) {
            // Edit über SwipeRight gestartet
            let changeEntry = this.getChecklistEntryById(id);
            this.setState({changeEntry});
        } else if (this.state.popup.argForButtonClicked) {
            // Edit über Popup gestartet
            let changeEntry = this.getChecklistEntryById(this.state.popup.argForButtonClicked);
            this.setState({changeEntry});
        }
    };

    // Prüft, ob Eintrag bereits gespeichert war,
    // löscht ggf. und triggert Popup mit Restore-Möglichkeit
    deleteEntryHandler = (id: number) => {
        this.setState(previousState => {
            let popupObject: IPopupObject;
            const checklist = {...previousState.checklist};
            let deletedEntry;
            for (let key in checklist) {
                if (checklist[key].id === id) {
                    deletedEntry = {...checklist[key]};
                    delete checklist[key];
                    popupObject = this.getPopupObject('delete', deletedEntry!);
                    break;
                }
            }
            this.cleanupChecklistOrder(checklist, true);  // Gecheckte Einträge neu sortieren
            this.cleanupChecklistOrder(checklist, false);  // Ungecheckte Einträge neu sortieren
            //@ts-ignore
            if (!popupObject) {
                // Eintrag war noch nicht gespeichert (Edit-Icon in InputLine genutzt);
                // kein Löschen notwendig;
                popupObject = {show: false};
            }

            DataManager.saveJSONToLocalStorage('checklist', checklist);
            return {
                checklist,
                popup: popupObject,
                deletedEntry,
                changeEntry: undefined
            };
        });
        this.setPopupTimeout();
    };

    /*
    Aktualisiert die "order" (Anzeigereihenfolge) von Einträgen, z.B. nach dem Löschen:
    Sorgt dafür, dass die Reihenfolge immer von 1-n geht und dazwischen keine
    Zahl ausgelassen wird.
     */
    cleanupChecklistOrder = (checklist: IChecklist, orderCartList: boolean) => {
        const currentOrderToItemId: {[key: string]: string} = {};
        for (let key in checklist) {
            if (checklist[key].checked === orderCartList) {
                currentOrderToItemId[checklist[key].order] = key
            }
        }

        const currentOrders: string[] = Object.keys(currentOrderToItemId).sort();
        let newOrderPosition = 0;

        for (let i = 0; i < currentOrders.length; i++) {
            let currentItemId = Number(currentOrderToItemId[currentOrders[i]]);
            checklist[currentItemId].order = newOrderPosition;
            newOrderPosition += 1;
        }
    };

    // Zuletzt gelöschter Eintrag wird aus State geladen und wiederhergestellt
    restoreEntryHandler = () => {
        if (this.state.deletedEntry) {
            let deletedEntry = {...this.state.deletedEntry};
            if (deletedEntry) {
                this.saveEntryToList(deletedEntry);
                this.setState({deletedEntry: undefined});
            }
        }
    };

    // Gibt passenden Checklisteneintrag zu ID zurück
    getChecklistEntryById = (id: number): IProduct => {
        const checklist = {...this.state.checklist};
        let entry;
        for (let key in checklist) {
            if (id === checklist[key].id) {
                entry = {...checklist[key]};
            }
        }
        //@ts-ignore
        return entry;
    };

    changeOrderHandler = (orderById: number[]) => {
        this.setState(previousState => {
            let checklist = {...previousState.checklist};
            // orderById ist Array über jew. IDs in benötigter Reihenfolge
            for (let i = 0; i <= orderById.length; i++) {
                for (let key in checklist) {
                    if (checklist[key].id === orderById[i]) {
                        // wenn die ID des Objektes mit der benötigten übereinstimmt,
                        // ändere die Order auf die Position im Array
                        let updatedEntry = {...checklist[key]};
                        updatedEntry.order = i;
                        checklist[key] = updatedEntry;
                    }
                }
            }
            DataManager.saveJSONToLocalStorage('checklist', checklist);
            return {checklist};
        });
    };

    onLongPress = () => {
        if (!this.state.dragMode) {
            this.toggleDragModeHandler()
        }
    };

    highlightLongPress = () => {
        this.setState({highlightLongPress: true});
        window.setTimeout(() => {
            this.setState({highlightLongPress: false});
        }, 400);
    };

    endDragModeHandler = () => {
        this.toggleDragModeHandler();
        // this.setState({ popup: { show: false } });
    };

    toggleDragModeHandler = () => {
        this.setState(previousState => {
            return {dragMode: !previousState.dragMode};
        });
    };

    // Liefert swipebares LI nach Tap auf ein Child des Listenelementes
    getSwipeableListItem = (event: any) => {
        if (event.path) {
            return event.path.find((element: any) => {
                // verhindert Fehlermeldungen bei Klick auf SVG
                if (typeof element.className === 'string') {
                    return element.className.includes('swipeableElement');
                }
                return null;
            });
        }
    };

    // Triggert Translation und Vibration bei maximaler Swipelänge (Aktion ausführbar)
    onSwipeRight = (offsetX: number, event: TouchEvent) => {
        let swipeableListItem = this.getSwipeableListItem(event);
        if (!swipeableListItem) {
            return;
        }

        const translateX = Math.max(offsetX, -this.swipeWidth);
        swipeableListItem.firstChild.style = `transform: translateX(${-translateX}px)`;
        if (offsetX <= -this.swipeWidth && !this.state.swipeCompleted) {
            window.navigator.vibrate(50);
            this.setState({swipeCompleted: true});
        }
    };

    // Triggert Translation und Vibration bei maximaler Swipelänge (Aktion ausführbar)
    onSwipeLeft = (offsetX: number, event: TouchEvent) => {
        let swipeableListItem = this.getSwipeableListItem(event);
        if (!swipeableListItem) {
            return;
        }

        const translateX = Math.min(offsetX, this.swipeWidth);
        swipeableListItem.firstChild.style = `transform: translateX(${-translateX}px)`;
        if (offsetX >= this.swipeWidth && !this.state.swipeCompleted) {
            window.navigator.vibrate(50);
            this.setState({swipeCompleted: true});
        }
    };

    // Sofern das LI maximal verschoben ist, wird je nach Richtung eine Aktion ausgeführt
    onDragEnd = (offsetX: number, event: TouchEvent) => {
        let swipeableListItem = this.getSwipeableListItem(event);
        if (swipeableListItem) {
            if (offsetX > this.swipeWidth) {
                this.deleteEntryHandler(Number(swipeableListItem.id));
            } else if (offsetX < -this.swipeWidth) {
                this.toggleCheckHandler(Number(swipeableListItem.id));
            }
                swipeableListItem.firstChild.style = `transform: translateX(${0}px); transition: transform .5s ease;`;
        }
        this.setState({swipeCompleted: false});
    };

    cancelClearListHandler = () => {
        this.setState({clearList: false});
    };

    cancelShareListDialog = () => {
        this.setState({showShareDialog: false});
    }

    cancelImportSharedListDialog = () => {
        this.setState({shareId: null, shareKey: null, showImportSharedListDlg: false})
    }

    importSharedList = () => {
        this.setState({showImportSharedListDlg: true})
    }

    render() {
        // Prüfe, ob Einkaufswagen-Liste gerendert werden muss,
        // d. h. Items mit checked: true enthalten sind
        let cartListNeeded = false;
        for (let key in this.state.checklist) {
            const itemInfo = this.state.checklist[key];
            if (itemInfo.checked) {
                cartListNeeded = true;
                break;
            }
        }
        const isEmptyPage =
            Object.keys(this.state.checklist).length === 0 &&
            this.state.checklist.constructor === Object;

        return (
            <div className={[classes.ShoppingListPage].join(' ').trim()}>
                <ClearListHandler
                    initDelete={this.state.clearList}
                    onCloseDialog={this.cancelClearListHandler}/>
                {this.state.showShareDialog
                    ? <ShareListDialog onCloseDialog={this.cancelShareListDialog}/>
                    : null}
                {this.state.showImportSharedListDlg
                    ? <ImportSharedListDialog listId={this.state.shareId}
                                              listKey={this.state.shareKey!}
                                              onCloseDialog={this.cancelImportSharedListDialog}
                                              reloadListHandler={this.syncStateWithLocalStorage}/>
                    : null}
                <Popup
                    show={this.state.popup.show}
                    message={this.state.popup.message}
                    buttonText={this.state.popup.buttonText}
                    buttonClicked={this.state.popup.buttonClicked}
                />
                {this.state.showInfo ? (
                    <ProductInfo vgNum={this.state.showInfo} hideInfo={this.hideInfoHandler}/>
                ) : null}
                {this.state.entryToCreateFromChecklistItem ? (
                    <ConfirmCreateNewEntryModal
                        vgNum={this.state.entryToCreateFromChecklistItem.vgNum}
                        onOk={this.createDbEntryFromCheckListItem}
                        onCancel={this.hideCreateNewEntryModal}
                    />
                ) : null}
                <InputLine
                    addEntry={this.addEntryHandler}
                    editEntry={this.editNewItemEditHandler}
                />
                {this.state.searchResults.length === 0 ? null : (
                    <SearchResults results={this.state.searchResults}/>
                )}
                <div className={classes.shoppingListContent}>
                    {isEmptyPage
                        ? <div className={classes.NoEntrySpan}>
                            <div>Noch keine Einträge vorhanden</div>
                        </div>
                        : null}
                    <Checklist
                        list={{...this.state.checklist}}
                        toggleCheck={this.toggleCheckHandler}
                        showInfo={this.showInfoHandler}
                        changeVgNum={this.changeVgNumHandler}
                        idWithInvalidVgNum={this.state.idWithInvalidVgNum}
                        dragMode={this.state.dragMode}
                        onEditEntry={this.editEntryHandler}
                        onDeleteEntry={this.deleteEntryHandler}
                        orderChanged={this.changeOrderHandler}
                    />
                    {cartListNeeded
                        ? <ChecklistCart
                            list={{...this.state.checklist}}
                            toggleCheck={this.toggleCheckHandler}
                            showInfo={this.showInfoHandler}
                            changeVgNum={this.changeVgNumHandler}
                            idWithInvalidVgNum={this.state.idWithInvalidVgNum}
                            dragMode={this.state.dragMode}
                            editEntry={this.editEntryHandler}
                            deleteEntry={this.deleteEntryHandler}
                            orderChanged={this.changeOrderHandler}/>
                        : null}
                </div>
                {this.state.dragMode
                    ? <>
                        <div className={classes.stopEditingPlaceHolder}/>
                        <div className={classes.stopEditingLayerOverlay}
                             onClick={this.endDragModeHandler}>
                            BEARBEITEN BEENDEN
                        </div>
                    </>
                    : null}
                {this.state.changeEntry
                        ? <EditEntryForm
                            inputEntry={this.state.changeEntry}
                            saveEditedEntry={this.updateItemHandler}
                            cancelEdit={this.cancelEditHandler}
                            deleteEntry={this.deleteEntryHandler}
                            autoCheck={this.settings.autoCheck}/>
                        : null
                }
            </div>
        );
    }
}

export default withRouter(ShoppingListPage);
