import React, { Component } from 'react';
import classes from './Layout.module.scss';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import Sidebar from '../../components/Navigation/Sidebar/Sidebar';
import BottomNav from '../../components/Navigation/BottomNav/BottomNav';
import ListMenu from '../../components/ListMenu/ListMenu';

interface IState {
    showSidebar: boolean;
    showListMenu: boolean;
    showBottomNav: boolean;
    outerWidth?: number;
    outerHeight?: number;
    text: string;
}

class Layout extends Component<{}, IState> {
    state = {
        showSidebar: false,
        showListMenu: false,
        showBottomNav: true,
        outerWidth: undefined,
        outerHeight: undefined,
        text: ''
    };

    componentDidMount() {
        this.setState({
            outerWidth: window.outerWidth,
            outerHeight: window.outerHeight
        });
        window.addEventListener('resize', this.hideBottomNav);
    }

    hideBottomNav = () => {
        if (!this.state.outerWidth || !this.state.outerHeight) {
            return;
        }

        if (
            //  Höhe verringert sich deutlich, während Breite gleich bleibt
            this.state.outerHeight! - window.outerHeight > 50 &&
            this.state.outerWidth === window.outerWidth
        ) {
            this.setState({ showBottomNav: false });
        } else if (
            //  Höhe vergrößert sich deutlich, während Breite gleich bleibt
            window.outerHeight - this.state.outerHeight! > 50 &&
            this.state.outerWidth === window.outerWidth
        ) {
            this.setState({ showBottomNav: true });
        }

        this.setState({
            outerWidth: window.outerWidth,
            outerHeight: window.outerHeight
        });
    };

    sidebarClosedHandler = () => {
        this.setState({ showSidebar: false });
    };

    toggleSidebarHandler = () => {
        this.setState(prevState => {
            return { showSidebar: !prevState.showSidebar };
        });
    };

    toggleListMenuHandler = () => {
        this.setState(prevState => {
            return { showListMenu: !prevState.showListMenu };
        });
    };

    listMenuClosedHandler = () => {
        this.setState({ showListMenu: false });
    };

    render() {
        return (
            <div className={classes.layout}>
                <Toolbar
                    sidebarTogglerClicked={this.toggleSidebarHandler}
                    listMenuTogglerClicked={this.toggleListMenuHandler}
                />
                <Sidebar open={this.state.showSidebar} closed={this.sidebarClosedHandler} />
                <ListMenu open={this.state.showListMenu} closed={this.listMenuClosedHandler} />
                <main className={classes.content}>{this.props.children}</main>
                <BottomNav show={this.state.showBottomNav} />
            </div>
        );
    }
}
export default Layout;
