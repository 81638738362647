import React from 'react';
import classes from './DragModeItem.module.scss';
import { IProduct } from '../../shared/interfaces';
import { ReactComponent as DragHandle } from '../../img/icons/dragHandle.svg';
import { ReactComponent as TrashIcon } from '../../img/icons/trash.svg';
import { ReactComponent as PenIcon } from '../../img/icons/pen.svg';

import Button from '../UI/Button/Button';

interface IProps {
    info: IProduct;
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    //  toggleCheck: () => void;
    //  showInfo: (vgNum: number) => void;
}

const DragModeItem = (props: IProps) => {
    // function showInfo() {
    //     props.showInfo(props.info.vgNum!);
    // }
    function editEntry() {
        props.onEdit(props.info.id);
    }

    function deleteEntry() {
        props.onDelete(props.info.id);
    }

    return (
        <li className={[classes.dragModeItem, 'sortable'].join(' ')} id={String(props.info.id)}>
            <div className={classes.checkboxWrapper}>
                <div className={[classes.dragHandle, 'handle'].join(' ')}>
                    <DragHandle />
                </div>
                <div className={classes.itemInfo}>
                    <span className={classes.label}>{props.info.title}</span>
                    {props.info.amount && props.info.unit ? (
                        <span className={classes.amount}>
                            ({props.info.amount} {props.info.unit})
                        </span>
                    ) : null}
                </div>
            </div>
            <div className={classes.buttons}>
                <Button clicked={editEntry} buttonType="Icon">
                    <PenIcon style={{width: "1em", height: "1em", fill: 'var(--light-grey)', stroke: 'var(--light-grey)'}} />
                </Button>
                <Button clicked={deleteEntry} buttonType="Icon" className={classes.deleteButton}>
                    <TrashIcon style={{width: "1em", height: "1em", fill: 'var(--red)', stroke: 'var(--red)'}} />
                </Button>
            </div>
        </li>
    );
};

export default DragModeItem;
