import React, { MouseEvent, ReactNode } from 'react';
import classes from './Button.module.scss';

interface IProps {
    buttonType: 'Primary' | 'Secondary' | 'Icon';
    clicked: (event: MouseEvent) => void;
    disabled?: boolean;
    className?: string;
    children: ReactNode;
}

const Button = (props: IProps) => {
    let cls = [classes.Button, props.className, classes[props.buttonType]];
    if (props.disabled) {
        cls.push(classes.Disabled);
    }
    return (
        <button
            className={cls.join(' ')}
            onClick={props.clicked}
            disabled={props.disabled}
            type="button"
        >
            {props.children}
        </button>
    );
};

export default Button;
