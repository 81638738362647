import React, { Component, FocusEvent, ChangeEvent, MouseEvent } from 'react';
import classes from './NumberInput.module.scss';

interface IProps {
    decrease: () => void;
    increase: () => void;
    changed: (value: number) => void;
    value: number;
    id?: string;
}

interface IState {
    oldValue: number;
    value?: number | '';
}

class NumberInput extends Component<IProps, IState> {
    state = {
        oldValue: this.props.value,
        value: this.props.value
    };

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    UNSAFE_componentWillReceiveProps = (props: IProps) => {
        if (props.value !== this.props.value) {
            this.setState(() => {
                return { value: props.value };
            });
        }
    };

    _decreaseValue = (event: MouseEvent) => {
        event.preventDefault();
        this.props.decrease();
    };

    _increaseValue = (event: MouseEvent) => {
        event.preventDefault();
        this.props.increase();
    };

    _changeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            // Leeres Feld bei Backspace zur Korrektur einer Eingabe
            this.setState({value: event.target.value})
            return
        }
        let inputValue = Number(event.target.value);
        if (isNaN(inputValue)) {
            inputValue = this.state.oldValue;
        }
        this.props.changed(inputValue);
    };

    _focusInputHandler = () => {
        this.setState(() => {
            return {
                oldValue: this.props.value,
                value: ''
            };
        });
    };

    _unfocusInputHandler = (event: FocusEvent<HTMLInputElement>) => {
        if (event.currentTarget.value === '') {
            this.setState({ value: this.state.oldValue });
        }
    };

    render() {
        return (
            <div className={classes.numberInput}>
                <div
                    className={[classes.button, classes.less].join(' ')}
                    onClick={this._decreaseValue}
                >
                    -
                </div>
                <input
                    id={this.props.id}
                    type="number"
                    onChange={this._changeInputHandler}
                    value={this.state.value}
                    onFocus={this._focusInputHandler}
                    onBlur={this._unfocusInputHandler}
                />
                <div
                    className={[classes.button, classes.more].join(' ')}
                    onClick={this._increaseValue}
                >
                    +
                </div>
            </div>
        );
    }
}

export default NumberInput;
