import React from 'react';
import classes from './Backdrop.module.scss';

interface IProps {
    show: boolean;
    clicked: () => void;
    transparent?: boolean;
}
const Backdrop = (props: IProps) => {
    return <div
        className={[classes.Backdrop, props.transparent ? classes.transparent : null, props.show ? null : classes.hidden]
            .join(' ')
            .trim()}
        onClick={props.clicked}
    />
};

export default Backdrop;
