import { ISettings } from './interfaces';
import {APP_VERSION} from "./constants";

const defaultSettings: ISettings = {
    autoCheck: true,  // True, wenn in Einkaufsliste hinzugefügte VG-Nummern automatisch abgehakt werden sollen.
    sortAlphabetically: false,  // True für alphabetische Sortierung der Einkaufsliste
    currentCamera: 0,  // Index der zu verwendenden Kamera
    clearUncheckedItems: false,  // Falls True ist bei "Liste leeren" die Checkbox "Einkaufsliste" ausgewählt
    clearCheckedItems: true,  // Falls True ist bei "Liste leeren" die Checkbox "Einkaufswagen" ausgewählt
    clearDeposit: true,  // Falls True ist bei "Liste leeren" die Checkbox "Leergut" ausgewählt
    autoComplete: true,  // Falls False werden die Vorschläge in der Produktsuche unterdrückt.
    settingsVersion: APP_VERSION,
    // Nicht vergessen: Eintrag zu _upgradeSettings hinzufügen!
};

export let SettingsManager = new (class {
    settings: ISettings = { ...defaultSettings };

    initializeSettings = () => {
        let storedSettings = localStorage.getItem('settings');
        if (storedSettings !== null) {
            let settings = JSON.parse(storedSettings);
            this._upgradeSettings(settings);
            this.setSettings(settings);
        }
    };

    // Aktualisiert die Einstellungen (Legacy-Update)
    _upgradeSettings = (settings: Partial<ISettings>) => {
        if (settings.currentCamera === undefined) {
            settings.currentCamera = 0;
        }
        if (settings.settingsVersion === undefined) {
            settings.settingsVersion = '';
        }
        if (settings.autoComplete === undefined) {
            settings.autoComplete = true;
        }
        // @ts-ignore
        if (settings.ignoreDuplicates !== undefined) {
            // @ts-ignore
            delete settings.ignoreDuplicates;
        }
    };

    mergeSettings = (settings: Partial<ISettings>) => {
        const newSettings = {...this.settings, ...settings}
        this.setSettings(newSettings)
    }

    getSettings = () => {
        return {...this.settings};
    };

    setSettings = (newSettings: ISettings) => {
        this.settings = newSettings;
        localStorage.setItem('settings', JSON.stringify(newSettings));
    };

    resetSettings = () => {
        this.settings = { ...defaultSettings };
        localStorage.setItem('settings', JSON.stringify(this.settings));
    };
})();
