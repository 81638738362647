import React, { MouseEvent } from 'react';
import classes from './ChecklistItem.module.scss';
import LabelWithCheckbox from './LabelWithCheckbox/LabelWithCheckbox';
import VGNumInput from './VGNumInput/VGNumInput';
import CountryInfo from './CountryInfo/CountryInfo';
import { IProduct } from '../../../shared/interfaces';

interface IProps {
    info: IProduct;

    // Callback für Abhaken des Items
    toggleCheck: () => void;

    // Callback, um weitere Produktinformationen anzuzeigen. (Bei Klick auf Ländercode)
    showInfo: (vgNum: number) => void;

    // Callback, wenn über das Eingabefeld des Listenelements die VG-Nummer eingegeben wird.
    changeVgNum?: (vgNum: number, title: string, id: number) => void;

    // True, wenn das Item eine ungültige VG-Nummer hat.
    itemHasInvalidVgNum: boolean;

    onEditItem?: (id: number) => void
}

const ChecklistItem = (props: IProps) => {
    function showInfo(event: MouseEvent) {
        event.stopPropagation();
        props.showInfo(props.info.vgNum!);
    }

    function onLabelClicked() {
        if (props.onEditItem !== undefined) {
            props.onEditItem(props.info.id);
        }
    }

    return (
        <li
            className={[classes.swipeableElement, classes.checklistItem].join(' ')}
            id={String(props.info.id)}
        >
            <div className={[classes.checklistItemContent, classes.swipeWrapper].join(' ')}>
                <LabelWithCheckbox
                    active={props.info.checked}
                    label={props.info.title}
                    amount={props.info.amount}
                    unit={props.info.unit}
                    disabled={props.info.hasVgNum && props.info.vgNum === undefined}
                    onCheckboxClicked={props.toggleCheck}
                    onLabelClicked={onLabelClicked}
                />
                {props.info.vgNum ? (
                    <CountryInfo country={props.info.country || '-'} clicked={showInfo} />
                ) : (
                    <VGNumInput
                        changeVgNum={props.changeVgNum}
                        id={props.info.id}
                        title={props.info.title}
                        vgNumIsInvalid={props.itemHasInvalidVgNum}
                    />
                )}
            </div>
        </li>
    );
};

export default ChecklistItem;
