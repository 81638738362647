import Button from "../../components/UI/Button/Button";
import React from "react";
import css from './CookieDisclaimerPage.module.scss';

interface IProps {
    allowCookieCallback: () => void
}

export function CookieDisclaimerPage(props: IProps) {
    return <div className={css.CookieDisclaimerPage}>
        <div className={css.Disclaimer}>
            <h2 className={css.heading}>Wir speichern lokale Daten</h2>
            <p>Die App speichert Daten in Deinem Browser.</p>
            <p>Dabei handelt es sich zum Einen um eine Liste mit Informationen zu Produkten mit
                VG-Nummern.</p>
            <p>Zum Anderen wird Deine Einkaufsliste komplett lokal verwaltet.
                Dadurch kann die App auch offline verwendet werden.</p>
        </div>
        <Button buttonType='Primary' clicked={props.allowCookieCallback}>Akzeptieren</Button>
    </div>
}
