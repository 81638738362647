import React, { ReactNode } from 'react';
import classes from './NavigationItem.module.scss';
import { NavLink } from 'react-router-dom';

interface IProps {
    link: string;
    exact?: boolean;
    icon?: JSX.Element;
    isBottomNav?: boolean;
    children: ReactNode;
}

const NavigationItem = (props: IProps) => {
    return (
        <li className={classes.NavigationItem}>
            <NavLink
                to={props.link}
                activeClassName={[
                    classes.active,
                    props.isBottomNav ? classes.activeBottom : null
                ].join(' ')}
                exact={props.exact}
            >
                <div
                    className={[classes.icon, props.isBottomNav ? classes.iconBottom : null].join(
                        ' '
                    )}
                >
                    {props.icon}
                </div>
                {props.children}
            </NavLink>
        </li>
    );
};

export default NavigationItem;
