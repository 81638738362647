import React from 'react';
import classes from './LabelWithCheckbox.module.scss';
import Checkbox from '../../../Checkbox/Checkbox';

export interface IProps {
    label: string;
    amount?: number;
    unit?: string;

    // True, wenn die Checkbox angehakt ist
    active: boolean;

    // True, wenn die Checkbox deaktiviert ist. Ändert nur die Darstellung!
    disabled?: boolean;
    onCheckboxClicked?: () => void;
    onLabelClicked?: () => void;
}

/** Textfeld mit einer Checkbox. **/
const LabelWithCheckbox = (props: IProps) => {

    function onLabelClicked(event: any) {
        event.preventDefault();
        if (props.onLabelClicked !== undefined) {
            props.onLabelClicked();
        }
    }
    return (
        <label className={classes.container} onClick={onLabelClicked}>
            <Checkbox disabled={props.disabled} active={props.active}
                      onClick={props.onCheckboxClicked}/>
            <div className={classes.labelWrapper}>
                <span className={classes.label}>{props.label}</span>
                {props.amount && props.unit ? (
                    <span className={classes.amount}>
                        ({props.amount} {props.unit})
                    </span>
                ) : null}
            </div>
        </label>
    );
};

export default LabelWithCheckbox;
