import React, {PureComponent} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import CheckboxLabel from "../../../components/CheckboxLabel/CheckboxLabel";
import {SettingsManager} from "../../../shared/SettingsManager";
import classes from './ClearListHandler.module.scss';
import {DataManager} from "../../../shared/DataManager";
import {ViewContext} from "../../../context/ViewContext";

interface IProps extends RouteComponentProps {
    initDelete: boolean;
    onCloseDialog: () => void;
}

interface IState {
    error: boolean;
    clearUncheckedItems: boolean;
    clearCheckedItems: boolean;
    clearDeposit: boolean;
}

/**
 * Stellt die Löschfunktion auf den verschiedenen Seiten zur Verfügung
 */
class DeleteHandler extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            error: false,
            clearCheckedItems: SettingsManager.getSettings().clearCheckedItems,
            clearUncheckedItems: SettingsManager.getSettings().clearUncheckedItems,
            clearDeposit: SettingsManager.getSettings().clearDeposit
        };
    }

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    _deleteListHandler = () => {
        DataManager.clearData(
            this.state.clearCheckedItems,
            this.state.clearUncheckedItems,
            this.state.clearDeposit
        )
        SettingsManager.mergeSettings({
            clearCheckedItems: this.state.clearCheckedItems,
            clearUncheckedItems: this.state.clearUncheckedItems,
            clearDeposit: this.state.clearDeposit
        })
        this.context.actions.showPopup('Einträge gelöscht!')
        this.props.onCloseDialog();
    };

    toggleClearUncheckedItems = () => {
        this.setState({clearUncheckedItems: !this.state.clearUncheckedItems})
    }

    toggleClearCheckedItems = () => {
        this.setState({clearCheckedItems: !this.state.clearCheckedItems})
    }

    toggleClearDepositItems = () => {
        this.setState({clearDeposit: !this.state.clearDeposit})
    }

    render() {
        return (
            <div>
                {/* Mit Aufruf der Methode wird der Nutzer per Modal zur Bestätigung aufgefordert */}
                <Modal
                    show={this.props.initDelete}
                    modalClosed={this.props.onCloseDialog}
                >
                    <div>
                        <p>Einträge löschen?</p>
                        <CheckboxLabel active={this.state.clearUncheckedItems} label='Einkaufsliste'
                                       onClick={this.toggleClearUncheckedItems}/>
                        <CheckboxLabel active={this.state.clearCheckedItems} label='Einkaufswagen'
                                       onClick={this.toggleClearCheckedItems}/>
                        <CheckboxLabel active={this.state.clearDeposit} label='Leergut'
                                       onClick={this.toggleClearDepositItems}/>
                        <div className={classes.buttonContainer}>
                            <Button buttonType="Primary" clicked={this._deleteListHandler}>
                                Löschen
                            </Button>
                            <Button buttonType="Secondary" clicked={this.props.onCloseDialog}>
                                Abbrechen
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(DeleteHandler);
