import React from 'react';
import classes from './HelpEntry.module.scss';

export interface IProps {
    title: string;
    children: React.ReactElement;
}

interface IState {
    is_visible: boolean
}

/**
 * Ein Eintrag auf der Hilfe-Seite
 */
class HelpEntry extends React.Component<IProps> {
    public state: IState = {
        is_visible: false
    }

    toggleHelp = () => {
        this.setState({is_visible: !this.state.is_visible})
    }

    render() {
        const section_classlist = [classes.section]
        const heading_classlist = [classes.headline]
        if (this.state.is_visible) {
            section_classlist.push(classes.sectionExpanded)
            heading_classlist.push(classes.headlineOpen)
        }

        return (
            <div>
                <h4 onClick={this.toggleHelp}
                    className={heading_classlist.join(" ")}>{this.props.title}</h4>
                <div className={section_classlist.join(" ")}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default HelpEntry;
