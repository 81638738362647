import React from 'react';
import classes from './Checkbox.module.scss';

export interface IProps {
    // True, wenn die Checkbox angehakt ist
    active: boolean;

    // True, wenn die Checkbox deaktiviert ist. Ändert nur die Darstellung!
    disabled?: boolean;
    onClick?: () => void;
    inline?: boolean;
}

const Checkbox = (props: IProps) => {
    let activeClasses = [classes.input];
    if (props.inline) {
        activeClasses.push(classes.inline);
    }
    if (props.active) {
        activeClasses.push(classes.checked);
    }

    function doClick(event: any) {
        if (!props.onClick) {
            return
        }
        event.preventDefault();
        event.stopPropagation();
        if (!props.disabled && props.onClick !== undefined) {
            props.onClick();
        }
    }

    return (
        <div className={activeClasses.join(' ')}>
            {<input type="checkbox" disabled={props.disabled} />}
            <span onClick={doClick}
                className={[classes.checkmark, props.disabled ? classes.disabled : '']
                    .join(' ')
                    .trim()}
            />
        </div>
    );
};

export default Checkbox;
