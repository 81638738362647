import React, {Component, MouseEvent} from 'react'
import {IMenuItem} from "../components/ListMenu/ListMenu";

interface IState {
    toolbarTitle: string;
    menuItems: IMenuItem[];
    popupShown: boolean;
    popupMessage: string;
    popupAction?: string;
    popupCallback?: (event: MouseEvent) => void;
}

export interface IViewContext {
    state: IState,
    actions: {
        setToolbarTitle: (toolbarTitle: string) => void;
        setMenuItems: (menuItem: IMenuItem[]) => void;
        showPopup: (message: string, action?: string, callback?: (event: MouseEvent) => void)
            => void;
        resetPopup: () => void;
    }
}

export const ViewContext = React.createContext<IViewContext>({
    state: {
        toolbarTitle: 'Einkaufsliste',
        menuItems: [],
        popupShown: false,
        popupMessage: '',
        popupAction: '',
        popupCallback: () => {
        }
    },
    actions: {
        setToolbarTitle: () => {},
        setMenuItems: () => {},
        showPopup: () => {},
        resetPopup: () => {}
    }
});

class ViewContextStore extends Component<{}, IState> {
    public readonly state: IState = {
        toolbarTitle: 'Einkaufsliste',
        menuItems: [],
        popupShown: false,
        popupMessage: '',
        popupAction: '',
        popupCallback: () => {}
    };
    private resetPopupTimeout?: number;

    setToolbarTitle = (toolbarTitle: string) => {
        this.setState({ toolbarTitle });
    };

    setMenuItems = (menuItems: IMenuItem[]) => {
        this.setState({menuItems})
    }

    showPopup = (message: string, action?: string, callback?: (event: MouseEvent) => void) => {
        if (this.resetPopupTimeout) {
            window.clearTimeout(this.resetPopupTimeout);
            this.resetPopupTimeout = undefined;
        }
        this.setState({
            popupShown: true,
            popupMessage: message,
            popupAction: action,
            popupCallback: callback
        });
        this.resetPopupTimeout = window.setTimeout(this.resetPopup, 3000)
    };

    resetPopup = () => {
        if (this.resetPopupTimeout) {window.clearTimeout(this.resetPopupTimeout)}
        this.resetPopupTimeout = undefined;
        this.setState({
            popupShown: false,
            popupMessage: '',
            popupAction: '',
            popupCallback: () => {}
        })
    };

    render() {
        return (
            <ViewContext.Provider value={{
                state: this.state,
                actions: {
                    setToolbarTitle: this.setToolbarTitle,
                    showPopup: this.showPopup,
                    resetPopup: this.resetPopup,
                    setMenuItems: this.setMenuItems,
                }
            }}>
                {this.props.children}
            </ViewContext.Provider>
        );
    }
}

export default ViewContextStore;
