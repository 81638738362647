import React from 'react';
import classes from './Toolbar.module.scss';
import SidebarToggler from './SidebarToggler/SidebarToggler';
import ListMenuToggler from './ListMenuToggler/ListMenuToggler';
import { ViewContext } from '../../../context/ViewContext';

interface IProps {
    sidebarTogglerClicked: () => void;
    listMenuTogglerClicked: () => void;
}

class Toolbar extends React.Component<IProps> {
    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    render() {
        return (
            <header className={classes.toolbar}>
                <div className={classes.wrapper}>
                    <div className={classes.sidebarToggler}>
                        <SidebarToggler clicked={this.props.sidebarTogglerClicked} />
                    </div>
                    <div className={classes.shoppingListSelect}>
                        {this.context.state.toolbarTitle}
                    </div>
                </div>
                <div className={classes.listMenuToggler}>
                    <ListMenuToggler clicked={this.props.listMenuTogglerClicked} />
                </div>
            </header>
        );
    }
}

export default Toolbar;
