import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import classes from './App.module.scss';
import Layout from './hoc/Layout/Layout';
import {Spinner} from './components/UI/Spinner/Spinner';
import ScannerPage from './pages/ScannerPage/ScannerPage';
import ShoppingListPage from './pages/ShoppingListPage/ShoppingListPage';
import DepositPage from './pages/DepositPage/DepositPage';
import CheckoutPage from './pages/CheckoutPage/CheckoutPage';
import './shared/styles.module.scss';
import {DataManager} from './shared/DataManager';
import NewsPage from './pages/NewsPage/NewsPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import {SettingsManager} from './shared/SettingsManager';
import FeedbackPage from './pages/FeedbackPage/FeedbackPage';
import ViewContextStore from './context/ViewContext';
import {MainPopup} from "./components/UI/Popup/Popup";
import AboutPage from "./pages/AboutPage/AboutPage";
import {CookieDisclaimerPage} from "./pages/CookieDisclaimerPage/CookieDisclaimerPage";
import WhatsNewPage from "./pages/WhatsNewPage/WhatsNewPage";
import HelpPage from "./pages/HelpPage/HelpPage";

interface IState {
    fetchedData: boolean;
    cookiesAllowed: boolean
}

class App extends Component<{}, IState> {
    state: IState = {
        fetchedData: false,
        cookiesAllowed: localStorage.getItem('CookiesAllowed') === 'true'
    };

    componentDidMount() {
        if (localStorage.getItem('CookiesAllowed') === 'true') {
            this.getData()
        }
    }

    getData = () => {
        DataManager.initializeData().then(() => this.setState({fetchedData: true}));
        SettingsManager.initializeSettings();
    };

    setCookiesAllowed = () => {
        localStorage.setItem('CookiesAllowed', 'true');
        this.setState({cookiesAllowed: true});
        this.getData();
    };

    render() {
        if (!this.state.cookiesAllowed) {
            return <CookieDisclaimerPage allowCookieCallback={this.setCookiesAllowed}/>;
        }
        return this.state.fetchedData ? <InnerApp/> : <Spinner/>;
    }
}

function InnerApp() {
    return (
        <div className={classes.App}>
            <ViewContextStore>
                <Layout>
                    <MainPopup/>
                    <Switch>
                        <Redirect from="/" exact to="/list"/>
                        <Route path="/scanner" component={ScannerPage}/>
                        <Route path="/list" component={ShoppingListPage}/>
                        <Route path="/deposit" component={DepositPage}/>
                        <Route path="/checkout" component={CheckoutPage}/>
                        <Route path="/news" component={NewsPage}/>
                        <Route path="/settings" component={SettingsPage}/>
                        <Route path="/feedback" component={FeedbackPage}/>
                        <Route path="/about" component={AboutPage}/>
                        <Route path="/changelog" component={WhatsNewPage}/>
                        <Route path="/help" component={HelpPage}/>
                        <Route
                            path="/"
                            render={() => (
                                <div style={{"position": "absolute", "top": "50%", "left": "50%", "transform": "translate(-50%,-50%)"}}>
                                    <span style={{whiteSpace: "nowrap"}}>Diese Seite muss erst noch gebaut werden!</span><br /><br />
                                    <span>Mehr in Kürze!</span>
                                </div>
                            )}
                        />
                    </Switch>
                </Layout>
            </ViewContextStore>
        </div>
    );
}

export default App;
