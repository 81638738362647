import React from 'react';
import classes from './BottomNav.module.scss';
import NavigationItem from '../NavigationItem/NavigationItem';

// Logo Imports:
import { ReactComponent as ChecklistIcon } from '../../../img/icons/checklist.svg';
import { ReactComponent as DepositIcon } from '../../../img/icons/deposit.svg';
import { ReactComponent as CheckoutIcon } from '../../../img/icons/checkout.svg';

interface IProps {
    show: boolean;
}

const BottomNav = (props: IProps) => {
    return (
        <nav className={[classes.BottomNav, props.show ? null : classes.hide].join(' ').trim()}>
            <ul>
                <NavigationItem link="/list" icon={<ChecklistIcon />} isBottomNav={true}>
                    LISTE
                </NavigationItem>
                <NavigationItem link="/deposit" icon={<DepositIcon />} isBottomNav={true}>
                    LEERGUT
                </NavigationItem>
                <NavigationItem link="/checkout" icon={<CheckoutIcon />} isBottomNav={true}>
                    KASSE
                </NavigationItem>
            </ul>
        </nav>
    );
};

export default BottomNav;
