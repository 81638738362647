import React from 'react';
import { IDeposit } from '../../../shared/interfaces';
import classes from './CheckoutDepositListItem.module.scss';

export interface IProps {
    depositInfo: IDeposit;
}

interface IState {
    checked: boolean
}

class CheckoutDepositListItem extends React.Component<IProps, IState> {
    public state: IState = {
        checked: false
    }

    toggleCheckState = () => {
        this.setState({checked: !this.state.checked})
    }

    render() {
        let itemStyle = classes.checkoutDepositListItem;
        if (this.state.checked) {
            itemStyle += ` ${classes.checkoutListItemSharedDisabledItem}`
        }
        return (
            <li className={itemStyle} onClick={this.toggleCheckState}>
                <div className={classes.itemInfo}>
                    <span className={classes.name}>{this.props.depositInfo.name}</span>
                </div>
                <div className={classes.vgNum}>
                    {this.props.depositInfo.value > 1 ? (
                        <span className={classes.pieces}>{this.props.depositInfo.value} &times; </span>
                    ) : null}{this.props.depositInfo.vgNum}</div>
            </li>
        );
    }
}

export default CheckoutDepositListItem;
