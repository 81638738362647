import React, {FormEvent, MouseEvent} from 'react';
import classes from './VGNumInput.module.scss';

interface IProps {
    changeVgNum: ((event: number, title: string, id: number) => void) | undefined;
    vgNumIsInvalid: boolean;
    title: string;
    id: number
}

const VGNumInput = (props: IProps) => {
    function changeVgNum(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (props.changeVgNum !== undefined) {
            props.changeVgNum(
                Number((event.currentTarget.elements[0] as HTMLInputElement).value),
                props.title,
                props.id);
        }
    }

    function preventToggle(event: MouseEvent) {
        event.stopPropagation();
    }

    return (
        <div
            className={[classes.vgNumInput, props.vgNumIsInvalid ? classes.isInvalid : '']
                .join(' ')
                .trim()}
            onClick={preventToggle}
        >
            <label>
                <form onSubmit={changeVgNum}>
                    <input type="number" placeholder="VG-Nr."/>
                    <small className={classes.errorMessage}>Eingabe überprüfen</small>
                </form>
            </label>
        </div>
    );
};

export default VGNumInput;
