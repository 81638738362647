import React, { ReactNode } from 'react';
import { IProductFromDB } from '../../shared/interfaces';
import classes from './SearchResults.module.scss';

export interface IProps {
    results: IProductFromDB[];
}

const SearchResults = (props: IProps) => {
    const searchResultsList: ReactNode[] = [];
    props.results.map(result => {
        searchResultsList.push(
            <li>
                {result.vgNum} - {result.title}
            </li>
        );
        return null;
    });
    return <ul className={classes.searchResults}>{searchResultsList}</ul>;
};

export default SearchResults;
