import React from 'react';
import classes from './CheckoutList.module.scss';
import CheckoutListItem from './CheckoutListItem/CheckoutListItem';
import { IChecklist } from '../../shared/interfaces';

export interface IProps {
    list: IChecklist;
}

const CheckoutList = (props: IProps) => {
    let checkoutList = [];
    for (let key in props.list) {
        const itemInfo = props.list[key];
        if (itemInfo.checked && itemInfo.vgNum && itemInfo.vgNum >= 1000) {
            checkoutList.push(<CheckoutListItem key={itemInfo.id} info={itemInfo} />);
        }
    }
    return <ul className={classes.checkoutList}>{checkoutList}</ul>;
};

export default CheckoutList;
