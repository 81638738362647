import React from 'react';
import classes from './Cart.module.scss';
// import classes from '../Checklist/Checklist.module.scss';
import ChecklistItem from '../Checklist/ChecklistItem/ChecklistItem';
import DragModeItem from '../DragModeItem/DragModeItem';
//@ts-ignore
import Sortable from 'react-sortablejs';
import {IChecklist} from '../../shared/interfaces';
import {SettingsManager} from '../../shared/SettingsManager';

interface IProps {
    list: IChecklist;
    toggleCheck: (key: number) => void;
    showInfo: (vgNum: number) => void;
    changeVgNum: (vgNum: number, title: string, id: number) => void;
    idWithInvalidVgNum?: number;
    dragMode: boolean;
    editEntry: (vgNum: number) => void;
    deleteEntry: (vgNum: number) => void;
    orderChanged: (order: number[]) => void;
}

const ChecklistCart = (props: IProps) => {
    function showInfo(vgNum: number) {
        props.showInfo(vgNum);
    }

    function itemHasInvalidVgNum(id: number) {
        return id === props.idWithInvalidVgNum;
    }

    let cartList = [];
    let sortedEntries = [];
    for (let key in props.list) {
        sortedEntries.push(props.list[key]);
    }
    const sort_alphabetically = SettingsManager.getSettings().sortAlphabetically
    if (sort_alphabetically) {
        sortedEntries.sort((a, b) => a.title < b.title ? -1 : 1);
    } else {
        sortedEntries.sort((a, b) => a.order < b.order ? -1: 1);
    }
    for (let itemInfo of sortedEntries) {
        if (itemInfo.checked) {
            cartList.push(
                !props.dragMode ? (
                    <ChecklistItem
                        key={itemInfo.id}
                        info={itemInfo}
                        toggleCheck={() => props.toggleCheck(itemInfo.id)}
                        showInfo={showInfo}
                        changeVgNum={props.changeVgNum}
                        itemHasInvalidVgNum={itemHasInvalidVgNum(itemInfo.id)}
                        onEditItem={props.editEntry}
                    />
                ) : (
                    <DragModeItem
                        key={itemInfo.id}
                        info={itemInfo}
                        onDelete={props.deleteEntry}
                        onEdit={props.editEntry}
                    />
                )
            );
        }
    }

    return (
        <div className={classes.cart}>
            <h3>Im Einkaufswagen</h3>
            <ul className={classes.checklist}>
                <Sortable
                    options={{
                        dataIdAttr: 'id',
                        draggable: '.sortable',
                        handle: '.handle',
                        animation: 150
                    }}
                    onChange={(order: string[]) => {
                        props.orderChanged(
                            order.map((orderAsString: string) => Number(orderAsString))
                        );
                    }}
                >
                    {cartList}
                </Sortable>
            </ul>
        </div>
    );
};

export default ChecklistCart;
