import React, {Component} from 'react';
import classes from './HelpPage.module.scss';
import {ViewContext} from '../../context/ViewContext';
import HelpEntry from "../../components/HelpEntry/HelpEntry";

/**
 * Die Hilfe-Seite.
 */
class HelpPage extends Component<{}> {

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    componentDidMount() {
        this.context.actions.setToolbarTitle('Hilfe');
    }

    render() {
        return (
            <div className={classes.helpPage}>
                <h3>Problemlösungen</h3>
                <HelpEntry title="Barcode-Scanner zeigt kein Bild (Android)">
                    <div>
                        <div>Bitte prüfe die Berechtigung für den Kamera-Zugriff.</div>
                        <ul>
                            <li>In den Browser-Einstellungen muss der VG-App Zugriff auf
                                die Kamera erlaubt sein.
                            </li>
                            <li>In den Systemeinstellungen muss dem Browser der Zugriff
                                auf die Kamera erlaubt sein.
                            </li>
                        </ul>
                        Ob Dein Browser auf die Kamera zugreifen kann, kannst Du auch
                        auf dieser Seite prüfen: <a
                        href="https://davidwalsh.name/demo/camera.php" target='_blank'
                        rel="noopener noreferrer">https://davidwalsh.name/demo/camera.php</a>
                    </div>
                </HelpEntry>
                <HelpEntry title="Barcode-Scanner zeigt kein Bild (iOS)">
                    <div>
                        <div>Bitte prüfe die Berechtigung für den Kamera-Zugriff.</div>
                        <ul>
                            <li>In den Safari-Einstellungen muss der VG-App Zugriff auf
                                die Kamera erlaubt sein.
                            </li>
                            <li>In den Systemeinstellungen muss Safari der Zugriff auf
                                die Kamera erlaubt sein.
                            </li>
                            <li>Falls die App vom HomeScreen aus gestartet wird, muss
                                der VG-App in den Systemeinstellungen der Zugriff auf
                                die Kamera erlaubt werden.
                            </li>
                            <li>Es kann sein, dass die
                                Berechtigungen entzogen werden, wenn die App
                                aktualisiert wird.</li>
                        </ul>
                        <div>Unter iOS 13.3 ist der Kamerazugriff nicht möglich, wenn
                            die App an den HomeScreen angepinnt ist!
                        </div>
                        <div>Ob Dein Browser auf die Kamera zugreifen kann, kannst Du
                            auch auf dieser Seite prüfen: <a
                                href="https://davidwalsh.name/demo/camera.php"
                                target='_blank'
                                rel="noopener noreferrer">https://davidwalsh.name/demo/camera.php</a>
                        </div>
                    </div>
                </HelpEntry>
                <HelpEntry title="Einkaufsliste nach Neustart leer">
                    <div>Wenn nach einem Neustart der App die Einkaufsliste gelöscht
                        wurde, dann entfernt der Browser wahrscheinlich alle
                        Webseitendaten beim Schließen. Dies passiert zum Beispiel wenn
                        Du Firefox Klar verwendest. In diesem Fall prüfe bitte Deine
                        Einstellungen oder versuche einen anderen Browser. Am besten
                        werden Chromium-basierte Browser unterstützt. Eine
                        datenschutzfreundliche Alternative auf Chromium-Basis ist
                        Vivaldi.
                    </div>
                </HelpEntry>
                <h3>Tipps</h3>
                <HelpEntry title="Wischgesten">
                    <div>
                        <div>Du kannst in der Einkaufsliste Einträge schneller abhaken
                            oder entfernen, indem Du Wischgesten verwendest:
                        </div>
                        <ul>
                            <li>Wische auf einem Eintrag von links nach rechts, um einen
                                Eintrag abzuhaken.
                            </li>
                            <li>Wische von rechts nach links, um einen Eintrag zu
                                entfernen.
                            </li>
                        </ul>
                    </div>
                </HelpEntry>
                <HelpEntry title="Gedächtnishilfe auf der Kassen-Seite">
                    <div>
                        Auf der "Kasse"-Seite kannst Du Einträge die Du angesagt hast
                        antippen. Dann werden diese durchgestrichen dargestellt und Du
                        weist, was Du noch ansagen musst.
                    </div>
                </HelpEntry>
                <HelpEntry title="Backwaren">
                    <div>
                        Du kannst auch Backwaren in der VG-App notieren. Trage dafür
                        einfach die dreistellige VG-Nummer in das Eingabefeld ein!
                    </div>
                </HelpEntry>
                <HelpEntry title="Teilen von Einträgen">
                    <div>
                        Du kannst eine Einkaufsliste mit anderen teilen. Klicke dafür
                        einfach in der Einkaufsliste im Menü oben rechts auf den Punkt
                        "Liste teilen".
                        Die geteilte Liste wird verschlüsselt für 7 Tage auf dem
                        VG-Server gespeichert. Wir können nicht sehen, was in der Liste
                        steht. Du kannst die Liste auf jedem Gerät
                        abrufen.
                    </div>
                </HelpEntry>
                <HelpEntry title="Was kann ich scannen?">
                    <div>
                        Mit der Kamera kannst Du sowohl Barcodes, als auch QR-Codes
                        scannen, die beim Gemüse oder bei Backwaren aushängen. <br />
                        Außerdem kannst Du beim Teilen einer Liste den Link als
                        QR-Code scannen.<br />
                        Nicht unterstützt wird das Scannen von Barcodes auf den
                        Produkten selbst.
                    </div>
                </HelpEntry>
                <div style={{minHeight: "1rem", width: "1px"}} />
            </div>
        );
    }
}

export default HelpPage;
