import React from 'react';
import classes from './Spinner.module.scss';
// Spinner entnommen von https://loading.io/css/ (unter CC0-Lizenz)

/* Ein Ladekringel. */
export const LoadingCircle = () => {
    return (
        <div className={classes.Spinner}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
    </div>
    );
};

/* Ein Ladekringel über die ganze Seite */
export const Spinner = () => {
    return (
        <div className={classes.SpinnerViewport}>
            {LoadingCircle()}
        </div>
    );
};
