import React from 'react';
import classes from './NavigationMenu.module.scss';
import NavigationItem from '../NavigationItem/NavigationItem';

// Logo Imports:
import { ReactComponent as Barcode } from '../../../img/icons/barcode.svg';
import { ReactComponent as ChecklistIcon } from '../../../img/icons/checklist.svg';
import { ReactComponent as DepositIcon } from '../../../img/icons/deposit.svg';
import { ReactComponent as CheckoutIcon } from '../../../img/icons/checkout.svg'
import { ReactComponent as HelpIcon } from '../../../img/icons/help.svg';
import { ReactComponent as ChangeLogIcon } from '../../../img/icons/changelog.svg';
import { ReactComponent as SettingsIcon } from '../../../img/icons/settings.svg';
import { ReactComponent as FeedbackIcon } from '../../../img/icons/feedback.svg';
import { ReactComponent as AboutIcon } from '../../../img/icons/about.svg';

const NavigationMenu = () => {
    return (
        <div className={classes.navigationMenu}>
            <h4>Einkaufen</h4>
            <ul>
                <NavigationItem link="/scanner" icon={<Barcode />} exact>
                    Scanner
                </NavigationItem>
                <NavigationItem link="/list" icon={<ChecklistIcon />}>
                    Einkaufsliste
                </NavigationItem>
                <NavigationItem link="/deposit" icon={<DepositIcon />}>
                    Leergut
                </NavigationItem>
                <NavigationItem link="/checkout" icon={<CheckoutIcon />}>
                    Zur Kasse
                </NavigationItem>
            </ul>
            <h4>Informationen</h4>
            <ul>
                <NavigationItem link="/help" icon={<HelpIcon />}>Hilfe</NavigationItem>
                <NavigationItem link="/changelog" icon={<ChangeLogIcon />}>Changelog</NavigationItem>
            </ul>
            <h4>Einstellungen & Kontakt</h4>
            <ul>
                <NavigationItem link="/settings" icon={<SettingsIcon />}>Einstellungen</NavigationItem>
                <NavigationItem link="/feedback" icon={<FeedbackIcon />}>Feedback</NavigationItem>
                <NavigationItem link="/about" icon={<AboutIcon />}>Impressum</NavigationItem>
            </ul>
        </div>
    );
};

export default NavigationMenu;
