import React from 'react';
import classes from './DepositListItem.module.scss';
import NumberInput from '../UI/NumberInput/NumberInput';
import {IDeposit} from '../../shared/interfaces';

interface IProps {
    type: IDeposit;
    id: string;
    change: (id: string, value: number) => void;
    increase: (id: string) => void;
    decrease: (id: string) => void;
}

const DepositInput = (props: IProps) => {
    function increase() {
        props.increase(props.id);
    }

    function decrease() {
        props.decrease(props.id);
    }

    function change(value: number) {
        props.change(props.id, value);
    }

    return (
        <li className={classes.depositInput}>
            <NumberInput
                value={props.type.value}
                increase={increase}
                decrease={decrease}
                changed={change}
            />
            <span className={classes.depositName}>{props.type.name} </span><span
            className={classes.vgNum}>{props.type.vgNum}</span>
        </li>
    );
};

export default DepositInput;
