import React, { Component } from 'react';
import classes from './ScannerPage.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DataManager } from '../../shared/DataManager';

import Scanner from './Scanner/Scanner';
import { Result } from '@zxing/library';
import { ViewContext } from '../../context/ViewContext';

class ScannerPage extends Component<RouteComponentProps> {

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    componentDidMount() {
        this.context.actions.setToolbarTitle('Scanner');
    }

    _onDetected = (result: Result) => {
        let resultText = result.getText();
        let vgNum = NaN;
        if (resultText.startsWith('https://app.vg-dresden.de/')) {
            window.open(resultText, '_self')
        }
        if (resultText.startsWith('VG')) {
            // QR-Code enthält folgende Daten: "VG <Nummer> <Einheit> <Bezeichnung> <Herkunftsland>"
            vgNum = Number(resultText.split(' ')[1]);
        }
        if (!isNaN(Number(resultText)) && resultText.length === 8) {
            // Barcode enthält 8 stellen mit Prüfziffer, z.B. 00073448 für VG-Nummer = 7344
            vgNum = Number(resultText.slice(3, resultText.length - 1));
        }
        if (!isNaN(vgNum)) {
            const product = DataManager.addProductToChecklist({vgNum: Number(vgNum)});
            if (product == null) {
                this.context.actions.showPopup(`Kein Produkt mit der Nummer ${vgNum} gefunden!`)
            } else {
                try {
                    window.navigator.vibrate(150);
                } catch(e) {}
                this.context.actions.showPopup(`${product.title} hinzugefügt.`)
            }
        }
    };

    render() {
        return (
            <div className={classes.scannerPage}>
                <Scanner onDetected={this._onDetected} />
            </div>
        );
    }
}

export default withRouter(ScannerPage);
