import React, {FormEvent, PureComponent} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import {DataManager} from "../../../shared/DataManager";
import {ViewContext} from "../../../context/ViewContext";
import {IProduct} from "../../../shared/interfaces";
import {LoadingCircle} from "../../../components/UI/Spinner/Spinner";
import classes from "./ImportSharedListDialog.module.scss";
import {SettingsManager} from "../../../shared/SettingsManager";

interface IProps extends RouteComponentProps {
    reloadListHandler: CallableFunction;
    listId: string | null;
    listKey: string | null;
    onCloseDialog: () => void;
}

interface IState {
    loadingFinished: boolean,
    showSharedUrlInputEntry: boolean
}

/**
 * Ermöglicht es, eine geteilte Liste zu importieren.
 */
class ImportSharedListDialog extends PureComponent<IProps, IState> {
    private listData: IProduct[] = []
    private duplicateEntries: IProduct[] = []
    private previousListData: {[key: number]: IProduct} = {}

    public state: IState = {
        loadingFinished: false,
        showSharedUrlInputEntry: !Boolean(this.props.listId && this.props.listKey)
    }

    async componentDidMount() {
        if (this.props.listId && this.props.listKey) {
            this.retrieveSharedList(this.props.listId, this.props.listKey)
        }
    }

    async retrieveSharedList(listId: string, listKey: string) {
        this.duplicateEntries = []
        this.listData = await DataManager.retrieveSharedList(listId, listKey)
        this.previousListData = await DataManager.getJSONFromLocalStorage('checklist');

        const already_included_product_titles = []
        for (let entry of Object.values(this.previousListData)) {
            already_included_product_titles.push(entry.title)
        }
        const duplicate_entries_indices = []
        for (let i=0; i < this.listData.length; i++) {
            let product = this.listData[i]
            if (already_included_product_titles.indexOf(product.title) !== -1) {
                duplicate_entries_indices.push(i)
                this.duplicateEntries.push(product)
            }
        }
        for (let index of duplicate_entries_indices.reverse()) {
            this.listData.splice(index, 1)
        }

        this.setState({loadingFinished: true})
    }

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    _appendToListHandler = async () => {
        this._addDataToList()
    };

    _appendAllToListHandler = async () => {
        for (let product of this.duplicateEntries) {
            product.checked = false;
            // @ts-ignore
            delete product.id;
            // @ts-ignore
            delete product.date;
            DataManager.addProductToChecklist(product)
        }
        this._addDataToList(this.duplicateEntries.length)
    };

    _replaceListHandler = async () => {
        this.previousListData = await DataManager.getJSONFromLocalStorage('checklist');
        DataManager.clearChecklist()
        this._addDataToList()
    };

    _addDataToList = (offset?: number) => {
        offset = offset || 0
        for (let product of this.listData) {
            product.checked = false;
            // @ts-ignore
            delete product.id;
            // @ts-ignore
            delete product.date;
            DataManager.addProductToChecklist(product)
        }
        this.props.reloadListHandler();
        this.props.onCloseDialog();
        this.context.actions.showPopup(`${this.listData.length + offset} Einträge hinzugefügt!`, 'Rückgängig', this._undoAdd)
    }

    _undoAdd = () => {
        DataManager.saveJSONToLocalStorage('checklist', this.previousListData)
        this.props.reloadListHandler();
        this.context.actions.showPopup('Alte Liste wiederhergestellt!')
    }

    _getListButtons = () => {
        return <>
            <div><Button buttonType="Primary" clicked={this._appendToListHandler}>
                Nur neue Einträge hinzufügen
            </Button></div>
            {this.duplicateEntries.length === 0 ? null : <div>
                <Button buttonType="Secondary" clicked={this._appendAllToListHandler}>
                    Neue Einträge und Duplikate hinzufügen
                </Button>
            </div>}
            <div><Button buttonType="Secondary" clicked={this._replaceListHandler}>
                Meine Liste ersetzen
            </Button></div>
        </>
    }

    _onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        this._openUserUrl()
    }

    _openUserUrl = () => {
        window.open((document.getElementById('sharedUrlInput') as HTMLInputElement).value, '_self');
    }

    render() {
        if (this.state.showSharedUrlInputEntry) {
            return <Modal show={true} modalClosed={this.props.onCloseDialog}>
                <div>Geteilte URL angeben</div>
                <form onSubmit={this._onFormSubmit}>
                    <input id='sharedUrlInput'
                           className={classes.input}
                           placeholder='https://app.vg-dresden.de/shareid=...'
                    />
                </form>
                <div>
                    <Button buttonType='Primary' clicked={this._openUserUrl}>
                        Importieren
                    </Button>
                    <Button buttonType="Secondary" clicked={this.props.onCloseDialog}>
                        Abbrechen
                    </Button>
                </div>
            </Modal>
        }
        return (
            <Modal
                show={true}
                modalClosed={this.props.onCloseDialog}
            >
                <div>
                    <p>{this.listData.length != null ? this.listData.length + this.duplicateEntries.length : ''} Einträge
                        importieren</p>
                    {this.duplicateEntries.length !== 0
                        ? <p>(davon {this.duplicateEntries.length}
                            {this.duplicateEntries.length === 1 ? ' Duplikat' : ' Duplikate'})</p>
                        : null}
                    <DivLoading show={!this.state.loadingFinished}/>
                    <div>
                        {this.state.loadingFinished ? this._getListButtons() : null}
                        <div><Button buttonType="Secondary" clicked={this.props.onCloseDialog}>
                            Abbrechen
                        </Button></div>
                    </div>
                </div>
            </Modal>
        );
    }
}

function DivLoading(props: {show: boolean}) {
    if (!props.show) {
        return null
    }
    return <div className={classes.loadingSpinnerContainer}>
        <LoadingCircle/>
    </div>
}

export default withRouter(ImportSharedListDialog);
