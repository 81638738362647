import React, { Fragment } from 'react';
import classes from './Sidebar.module.scss';
import { ReactComponent as Logo } from '../../../img/logo.svg';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import Backdrop from '../../UI/Backdrop/Backdrop';

interface IProps {
    open: boolean;
    closed: () => void;
}

const Sidebar = (props: IProps) => {
    let attachedClasses = [classes.sidebar, classes.close];
    if (props.open) {
        attachedClasses = [classes.sidebar, classes.open];
    }
    return (
        <Fragment>
            <Backdrop show={props.open} clicked={props.closed} />
            <div className={attachedClasses.join(' ')} onClick={props.closed}>
                <div className={classes.sidebarHeader}>
                    <div className={classes.logo}>
                        <Logo />
                    </div>
                    <div className={classes.siteID}>VG-App</div>
                </div>
                <NavigationMenu />
            </div>
        </Fragment>
    );
};

export default Sidebar;
