import React from 'react';
import classes from './SidebarToggler.module.scss';

interface IProps {
    clicked: () => void;
}

const SidebarToggler = (props: IProps) => {
    return (
        <div className={classes.sidebarToggler} onClick={props.clicked}>
            <div />
            <div />
            <div />
        </div>
    );
};

export default SidebarToggler;
