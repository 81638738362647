import React from 'react';
import classes from './Checklist.module.scss';
import ChecklistItem from './ChecklistItem/ChecklistItem';
import DragModeItem from '../DragModeItem/DragModeItem';
//@ts-ignore
import Sortable from 'react-sortablejs';
import {IChecklist} from '../../shared/interfaces';
import {SettingsManager} from '../../shared/SettingsManager';

interface IProps {
    list: IChecklist;
    toggleCheck: (key: number) => void;
    showInfo: (vgNum: number) => void;
    changeVgNum: (vgNum: number, title: string, id: number) => void;
    idWithInvalidVgNum?: number;

    onEditEntry: (vgNum: number) => void;
    onDeleteEntry: (vgNum: number) => void;

    // True, wenn der Drag'n'drop-Modus aktiv ist
    dragMode: boolean;

    // Callback bei Änderung der Sortierung
    orderChanged: (order: number[]) => void;
}

/**
 * Eine Checkliste, auf der verschiedene Produkte als abhakbare Liste angezeigt werden.
 * @constructor
 */
const Checklist = (props: IProps) => {
    function showInfo(vgNum: number) {
        props.showInfo(vgNum);
    }

    function itemHasInvalidVgNum(id: number) {
        return id === props.idWithInvalidVgNum;
    }

    function simplifiedTitle(title: string): string {
        return title.toLowerCase().replace('ä', 'a').replace('ö', 'o').replace('ü', 'u')
    }

    let checklist = [];
    let sortedEntries = [];
    for (let key in props.list) {
        sortedEntries.push(props.list[key]);
    }
    const sort_alphabetically = SettingsManager.getSettings().sortAlphabetically
    if (sort_alphabetically) {
        sortedEntries.sort((a, b) => simplifiedTitle(a.title) < simplifiedTitle(b.title) ? -1 : 1);
    } else {
        sortedEntries.sort((a, b) => a.order < b.order ? -1: 1);
    }
    for (let itemInfo of sortedEntries) {
        if (!itemInfo.checked) {
            checklist.push(
                !props.dragMode ? (
                    <ChecklistItem
                        key={itemInfo.id}
                        info={itemInfo}
                        toggleCheck={() => props.toggleCheck(itemInfo.id)}
                        showInfo={showInfo}
                        changeVgNum={props.changeVgNum}
                        itemHasInvalidVgNum={itemHasInvalidVgNum(itemInfo.id)}
                        onEditItem={props.onEditEntry}
                    />
                ) : (
                    <DragModeItem
                        key={itemInfo.id}
                        info={itemInfo}
                        onDelete={props.onDeleteEntry}
                        onEdit={props.onEditEntry}
                    />
                )
            );
        }
    }

    return (
        <ul className={classes.checklist}>
            {props.dragMode ? (
                <Sortable
                    options={{
                        dataIdAttr: 'id',
                        draggable: '.sortable',
                        handle: '.handle',
                        animation: 150
                    }}
                    onChange={(order: string[]) => {
                        props.orderChanged(
                            order.map((orderAsString: string) => Number(orderAsString))
                        );
                    }}
                >
                    {checklist}
                </Sortable>
            ) : (
                checklist
            )}
        </ul>
    );
};

export default Checklist;
