import React from 'react';
import classes from './CheckoutBakeryList.module.scss';
import CheckoutListItem from './CheckoutListItem/CheckoutListItem';
import { IChecklist } from '../../shared/interfaces';

export interface IProps {
    list: IChecklist;
}

const CheckoutBakeryList = (props: IProps) => {
    let checkoutList = [];
    for (let key in props.list) {
        const itemInfo = props.list[key];
        if (itemInfo.checked && itemInfo.vgNum && itemInfo.vgNum < 1000) {
            checkoutList.push(<CheckoutListItem key={itemInfo.id} info={itemInfo} />);
        }
    }
    return (
        <div className={classes.checkoutBakery}>
            <h3>Backwaren</h3>
            <ul className={classes.checkoutBakeryList}>{checkoutList}</ul>
        </div>
    );
};

export default CheckoutBakeryList;
