import React, {useContext} from 'react';
import classes from './ListMenuToggler.module.scss';
import {ViewContext} from "../../../../context/ViewContext";
import { ReactComponent as MenuIcon } from '../../../../img/icons/ellipsis.svg';

export interface IProps {
    clicked: () => void;
}

const ListMenuToggler = (props: IProps) => {
    const context = useContext(ViewContext)
    if (context.state.menuItems.length === 0) {return null}
    return (
        <div className={classes.listMenuToggler} onClick={props.clicked}>
            <MenuIcon />
        </div>
    );
};

export default ListMenuToggler;
