import React, {Component} from 'react';
import {BrowserMultiFormatReader, Result} from '@zxing/library';
import {SettingsManager} from '../../../shared/SettingsManager';
import {ReactComponent as CheckCircle} from '../../../img/icons/check-circle.svg';
import {ReactComponent as Flash} from '../../../img/icons/flash.svg';
import {ReactComponent as SwitchIcon} from '../../../img/icons/switch.svg';
import classes from './Scanner.module.scss';

interface IProps {
    onDetected: (result: Result) => void;
}

interface IState {
    torchActivated: boolean;
    videoNotAvailable: boolean;
    codeDetected: boolean;
}

class Scanner extends Component<IProps, IState> {
    state = {
        torchActivated: false,
        videoNotAvailable: false,
        codeDetected: false
    };
    private codeReader = new BrowserMultiFormatReader();
    private isUnWinding = false;
    private availableDevices: any[] = [];
    private closeCameraTimeout?: number;
    private focusCameraTimer?: number | null;

    async componentDidMount() {
        try {
            this.availableDevices = await this.codeReader.listVideoInputDevices();
        } catch {
            this.availableDevices = [];
        }
        if (this.availableDevices.length > 0) {
            try {
                this.codeReader.reset();
                await this._createVideoStream();
                return;
            } catch (e) {
                console.log('No video available: ', e);
            }
        }
        this.setState({ videoNotAvailable: true });
    }

    componentWillUnmount() {
        this.isUnWinding = true;
        this.closeCamera();
        let videoElem = window.document.getElementById('video') as HTMLMediaElement;
        if (videoElem) {
            let stream = videoElem.srcObject as MediaStream;
            if (!stream) {
                return;
            }
            let tracks = stream.getTracks();
            tracks.forEach(function(track: MediaStreamTrack) {
                track.stop();
            });
            videoElem.srcObject = null;
        }
    }

    _createVideoStream = async () => {
        const camIndex = SettingsManager.getSettings().currentCamera;
        const camId = this.availableDevices[camIndex].deviceId;

        // Kamera irgendwann schlafenlegen, falls der Nutzer sein Handy gesperrt hat
        // während die Kamera läuft.
        if (this.closeCameraTimeout) {
            window.clearTimeout(this.closeCameraTimeout);
        }
        if (this.focusCameraTimer) {
            window.clearTimeout(this.focusCameraTimer)
        }
        this.closeCameraTimeout = window.setTimeout(this.closeCamera, 300000);
        this.focusCameraTimer = window.setTimeout(this._enableAutofocus, 1000)
        this.codeReader.timeBetweenDecodingAttempts = 700;
        this.codeReader.decodeFromVideoDevice(camId, 'video', this._onDetected)

    };

    _enableAutofocus = () => {
        this.focusCameraTimer = null;
        // @ts-ignore
        this.codeReader.stream.getVideoTracks()[0].applyConstraints({focusMode:
                "continuous"})
    }

    _onDetected = (result: Result) => {
        if (!this.state.codeDetected && result != null && !this.isUnWinding) {
            this.props.onDetected(result)
            this.setState({codeDetected: true})
            setTimeout(this._unlockCameraAfterDetection, 700)
        }
    }

    _unlockCameraAfterDetection = () => {
        this.setState({codeDetected: false})
    }

    closeCamera = () => {
        try {
            this.codeReader.reset();
        } catch {}
        if (this.closeCameraTimeout) {
            clearTimeout(this.closeCameraTimeout);
            this.closeCameraTimeout = undefined
        }
    };

    switchCamera = () => {
        const settings = SettingsManager.getSettings();
        let newCamIndex = settings.currentCamera + 1;
        if (newCamIndex >= this.availableDevices.length) {
            newCamIndex = 0;
        }
        settings.currentCamera = newCamIndex;
        SettingsManager.setSettings(settings);
        this.codeReader.reset();
        this._createVideoStream();
    };

    activateTorch = () => {
        let stream = (document.getElementById('video') as HTMLMediaElement)
            .srcObject as MediaStream;
        let tracks = stream.getTracks();
        tracks.forEach((track: MediaStreamTrack) => {
            if (this.state.torchActivated) {
                // Die Torch ist so lange aktiv, wie der "Track" aktiv ist. Der Track ist aktiv,
                // solange der BarcodeReader aktiv ist.
                this.codeReader.reset();
                this._createVideoStream();
                this.setState({ torchActivated: false });
            } else {
                //@ts-ignore
                track.applyConstraints({ advanced: [{ torch: true }] });
                this.setState({ torchActivated: true });
            }
        });
    };

    render() {
        if (this.state.videoNotAvailable) {
            return <div className={classes.noScannerBlock}>
                <p className={classes.noScannerText}>Barcode-Scanner nicht verfügbar!</p>
                <p>Bitte prüfe, ob der Browser / die App die Berechtigung hat, auf
                    die Kamera zuzugreifen.
                </p>
            </div>
        }
        let detected_overlay = null
        if (this.state.codeDetected) {
            detected_overlay = <div className={classes.detectedOverlay}>
                <CheckCircle />
            </div>
        }
        return (
            <div className={classes.scanner}>
                {detected_overlay}
                <div className={classes.container}>
                    <div className={classes.centerLine} />
                </div>
                <div className={classes.video}>
                    <video playsInline id="video"/>
                </div>
                <div
                    className={[
                        classes.activateTorch,
                        this.state.torchActivated
                            ? classes.torchActivated
                            : classes.torchDeactivated
                    ].join(' ')}
                    onClick={this.activateTorch}
                >
                    <Flash />
                </div>
                <div
                    className={classes.switchCamera}
                    onClick={this.switchCamera}
                >
                    <SwitchIcon />
                </div>
            </div>
        );
    }
}

export default Scanner;
