import React, {Component} from 'react';
import classes from './SettingsPage.module.scss';
import Setting from './Setting/Setting';
import {SettingsManager} from '../../shared/SettingsManager';
import {ViewContext} from '../../context/ViewContext';

export interface IState {
    sortAlphabetically: boolean;
    autoCheck: boolean;
    autoComplete: boolean;
}

class SettingsPage extends Component<{}, IState> {
    state: IState = {
        autoCheck: false,
        sortAlphabetically: false,
        autoComplete: true,
    };

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    componentDidMount() {
        this.context.actions.setToolbarTitle('Einstellungen');
        let settings = SettingsManager.getSettings();
        this.setState({...settings});
    }

    componentDidUpdate = () => {
        const oldSettings = SettingsManager.getSettings();
        const newSettings = {...oldSettings, ...this.state};
        SettingsManager.setSettings(newSettings);
    };

    autoCheck = () => {
        this.setState(previousState => {
            return {autoCheck: !previousState.autoCheck};
        });
    };

    sortAlphabetically = () => {
        this.setState(previousState => {
            return {sortAlphabetically: !previousState.sortAlphabetically};
        });
    };

    autoComplete = () => {
        this.setState(previousState => {
            return {autoComplete: !previousState.autoComplete};
        });
    };

    render() {
        return (
            <ul className={classes.settingsPage}>
                <Setting
                    title="Einträge mit Nummern automatisch abhaken"
                    description="Jeder Eintrag wird automatisch abgehakt und in den Einkaufswagen verschoben, sobald er eine VG-Nummer erhält"
                    checked={this.state.autoCheck}
                    toggleCheck={this.autoCheck}
                />
                <Setting
                    title="Liste alphabetisch sortieren"
                    description="Einträge in der Einkaufsliste alphabetisch sortieren"
                    checked={this.state.sortAlphabetically}
                    toggleCheck={this.sortAlphabetically}
                />
                <Setting
                    title="Suchvorschläge anzeigen"
                    description="Suchvorschläge für das Eingabefeld auf der Einkaufslistenseite anzeigen. Die Suchvorschläge basieren auf den zuvor gesuchten Produkten."
                    checked={this.state.autoComplete}
                    toggleCheck={this.autoComplete}
                />
            </ul>
        );
    }
}

export default SettingsPage;
