import React from 'react';
import classes from './CheckboxLabel.module.scss';
import Checkbox from '../Checkbox/Checkbox';

export interface IProps {
    label: string;

    // True, wenn die Checkbox angehakt ist
    active: boolean;

    // True, wenn die Checkbox deaktiviert ist. Ändert nur die Darstellung!
    disabled?: boolean;

    onClick: () => void;
}

/** Textfeld mit einer Checkbox. **/
const CheckboxLabel = (props: IProps) => {
    return (
        <div className={classes.container} onClick={props.onClick}>
            <Checkbox disabled={props.disabled} active={props.active} onClick={props.onClick} />
            <div className={classes.labelWrapper}>
                <span className={classes.label}>{props.label}</span>
            </div>
        </div>
    );
};

export default CheckboxLabel;
