import {
    IChecklist,
    IChecksums,
    ICountryFromDB,
    IDepositFromDB,
    IDepositList,
    IManufacturerFromDB, IProduct,
    IProductFromDB,
    IStoredFeedback,
    IUnitFromDB
} from './interfaces';
import {SettingsManager} from "./SettingsManager";
import {getRandomPhrase, sortChecklist} from "./utilities";
import {AES, enc} from 'crypto-js';
import {EventManager} from "./EventManager";

// localname
// createdLocally

export let DataManager = new (class {
    vgProducts!: IProductFromDB[];
    userProductData!: { [key: number]: IProductFromDB };
    manufacturers!: IManufacturerFromDB[];
    countries!: ICountryFromDB[];
    units!: IUnitFromDB[];
    deposits!: IDepositFromDB[];
    checksums: IChecksums = {
        manufacturers: undefined,
        vgProducts: undefined,
        countries: undefined,
        units: undefined,
        deposits: undefined
    };

    /**
     * Gibt einen Produkteintrag anhand seiner VG-Nummer zurück. Lokal erstellte Einträge werden
     * bevorzugt.
     */
    getProductByVgNum = (vgNum: number): IProductFromDB => {
        let productEntry: IProductFromDB | undefined;
        productEntry = this.userProductData[vgNum];
        if (productEntry) {
            return { ...productEntry };
        }

        productEntry = this.vgProducts.find((product: IProductFromDB) => product.vgNum === vgNum);
        return productEntry
            ? { ...productEntry }
            : {
                  id: -1,
                  vgNum: -1,
                  unitID: -1,
                  manufacturerID: -1,
                  countryID: -1,
                  title: '-',
                  isRegional: false
              };
    };

    /**
     * Gibt ein Produkt aus der Datenbank zurück. Lokal gespeicherte Anpassungen werden *nicht*
     * berücksichtigt.
     */
    getProductFromDb = (vgNum: number): IProductFromDB | undefined => {
        const product = this.vgProducts.find((product: IProductFromDB) => product.vgNum === vgNum);
        return product ? { ...product } : undefined;
    };

    getDepositsFromLocalStorage = (): IDepositList => {
        let storedDeposit = this.getJSONFromLocalStorage('depositlist');
        let depositsListFromDB = this.getDepositsList();
        let depositObjectWithValues: IDepositList = {};

        for (let index in depositsListFromDB) {
            let id = depositsListFromDB[index].id;

            depositObjectWithValues[id] = { ...depositsListFromDB[index], value: 0 };

            if (storedDeposit[id]) {
                depositObjectWithValues[id].value = storedDeposit[id].value;
            }
        }

        return depositObjectWithValues;
    };

    getDepositsList = (): readonly IDepositFromDB[] => {
        return this.deposits;
    };

    getCountryCodeById = (id: number): string => {
        let countryEntry = this.countries.find((country: ICountryFromDB) => country.id === id);
        return countryEntry ? countryEntry.code : '-';
    };

    getCountryById = (id: number): string => {
        let countryEntry = this.countries.find((country: ICountryFromDB) => country.id === id);

        return countryEntry ? countryEntry.country : '-';
    };

    getUnitById = (id: number): string => {
        let unitEntry = this.units.find((unit: IUnitFromDB) => unit.id === id);

        return unitEntry ? unitEntry.unit : '-';
    };

    getManufacturerById = (id: number): string => {
        let manufacturerEntry = this.manufacturers.find(
            (manufacturer: IManufacturerFromDB) => manufacturer.id === id
        );

        return manufacturerEntry ? manufacturerEntry.manufacturer : '-';
    };

    /**
     * Leert die aktuelle Einkaufsliste. Es muss ggf. auf der Shoppinglist noch ein Refresh
     * gemacht werden, damit die Änderungen auch visuell übernommen werden.
     */
    clearChecklist = () => {
        this.saveJSONToLocalStorage('checklist', {});
    }

    clearData = (removeChecked: boolean, removeUnchecked: boolean, resetDeposit: boolean) => {
        let currentItems: IChecklist = this.getJSONFromLocalStorage('checklist')
        let newItems: IChecklist = {}
        let newItemId: number = 0
        for (let key in currentItems) {
            if (removeChecked && currentItems[key].checked) {
                continue
            } else if (removeUnchecked && !currentItems[key].checked) {
                continue
            }
            currentItems[key].id = newItemId
            newItems[newItemId] = currentItems[key]
            newItemId += 1
        }
        newItems = sortChecklist(newItems)
        this.saveJSONToLocalStorage('checklist', newItems)

        if (resetDeposit) {
            let depositState = this.getJSONFromLocalStorage('depositlist');
            for (let key in depositState) {
                depositState[key].value = 0;
            }
            this.saveJSONToLocalStorage('depositlist', depositState)
        }
        EventManager.emitEvent('updatedUserListsData', undefined)
    }

    addProductToChecklist = (product: Partial<IProduct>): null | IProduct => {
        let productFromDB: Partial<IProductFromDB> = {};
        if (product.vgNum) {
            let dbProduct = this.getProductByVgNum(product.vgNum);
            if (dbProduct.vgNum === product.vgNum) {
                productFromDB = dbProduct
            } else if (!product.title) {  // Kein Titel + keine VG-Nummer - was sollen wir hier hinzufügen?
                return null;
            }
        }

        const checklist = this.getJSONFromLocalStorage('checklist', {});
        let addedProduct;
        for (let product_id of Object.keys(checklist)) {
            const existing_product = checklist[product_id]  // type: IProduct
            const title = product.title || productFromDB.title
            if (`${existing_product.title}|${existing_product.unit||"Stück"}`
                == `${title}|${product.unit||"Stück"}`) {
                existing_product.amount += product.amount
                existing_product.checked = false
                existing_product.unit = existing_product.unit || "Stück"
                existing_product.date = Date.now()
                addedProduct = existing_product
            }
        }
        if (!addedProduct) {
            addedProduct = {
                ...productFromDB,
                amount: 1,
                checked: SettingsManager.getSettings().autoCheck,
                hasVgNum: product.vgNum != null,
                order: 1,
                ...product,
                date: Date.now(),
                id: this.getNextId(),
            };
            checklist[addedProduct.id] = addedProduct;
        }
        this.saveJSONToLocalStorage('checklist', checklist);
        // @ts-ignore
        return addedProduct
    };

    saveUserProduct = (product: IProductFromDB) => {
        this.userProductData[product.vgNum] = product;
        this.saveJSONToLocalStorage('userProducts', this.userProductData);
    };

    sendFeedback = async (title: string, mail: string, content: string) => {
        let requestSucceeded: boolean;
        try {
            let request = await fetch('https://app.vg-dresden.de/php/sendFeedback.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ title, mail, content })
            });
            let response = await request.text();
            requestSucceeded = response === 'OK';
        } catch (e) {
            console.log('Error occured when sending feedback: ', e);
            requestSucceeded = false;
        }

        if (requestSucceeded) {
            this.saveFeedback('', mail, '');
            return true;
        } else {
            this.saveFeedback(title, mail, content);
            return false;
        }
    };

    getLastFeedback = (): IStoredFeedback => {
        const item = localStorage.getItem('feedback');
        if (item) {
            return JSON.parse(item);
        }
        return { title: '', mail: '', content: '' };
    };

    saveFeedback = (title: string, mail: string, content: string) => {
        localStorage.setItem('feedback', JSON.stringify({ title, mail, content }));
    };

    /**
     * Teilt die Liste. Gibt den Link der geteilten Liste zurück.
     * @param listData - Liste mit IProduct-Items
     */
    shareList = async (listData: IProduct[]): Promise<string> => {
        let entryId: number = 0;
        const encryptionKey = getRandomPhrase(20);
        try {
            const json = JSON.stringify(listData);
            let jsonListData = AES.encrypt(json, encryptionKey).toString();
            let request = await fetch('https://app.vg-dresden.de/php/shareList.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ listData: jsonListData })
            });
            let response = await request.text();
            entryId = Number(response);
            if (isNaN(entryId)) {
                // noinspection ExceptionCaughtLocallyJS
                throw Error('Error occured when sharing list: ' + response)
            }
        } catch (e) {
            throw Error('Error occured when sharing list: ' + e)
        }

        return `https://app.vg-dresden.de/list?shareId=${entryId}#${encryptionKey}`
    };

    /**
     * Importiert eine geteilte Liste.
     * @param shareId - ID der geteilten Liste
     * @param shareKey - Key der geteilten Liste
     */
    retrieveSharedList = async(shareId: string, shareKey: string): Promise<IProduct[]> => {
        let request = await fetch('https://app.vg-dresden.de/php/getSharedList.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ shareId })
        });
        let response = await request.text();
        try {
            let decryptedResponse = AES.decrypt(response, shareKey).toString(enc.Utf8);
            return JSON.parse(decryptedResponse);
        } catch(e) {
            throw Error('An error occured while retrieving list: ' + e)
        }
    }

    // Synchronisiere die Daten der Datenbanken mit dem LocalStorage/lokalen Variablen
    async initializeData() {
        if (!navigator.onLine) {
            // keine Internetverbindung; lade Daten aus LS und
            // unterlasse DB-Anfragen
            this.fetchDataFromLocalStorage();
            return;
        }
        // Vergleiche Prüfsumme von DB mit der im LocalStorage hinterlegten,
        // d. h. ob Daten aktuell hinterlegt
        this.checksums = await this.getChecksums();
        let storedChecksums = this.getJSONFromLocalStorage('checksums');

        for (let identifier in this.checksums) {
            if (this.checksums[identifier] !== storedChecksums[identifier]) {
                // Daten veraltet, lade Produktliste neu und speichere Liste u. Checksum in LocalStorage
                console.log(`Update ${identifier}.`)
                switch (identifier) {
                    case 'vgProducts':
                        this.vgProducts = await this.getProductListFromDatabase();
                        this.saveJSONToLocalStorage('productList', this.vgProducts);
                        break;
                    case 'manufacturers':
                        this.manufacturers = await this.getManufacturersListFromDatabase();
                        this.saveJSONToLocalStorage('manufacturers', this.manufacturers);
                        break;
                    case 'countries':
                        this.countries = await this.getCountriesListFromDatabase();
                        this.saveJSONToLocalStorage('countries', this.countries);
                        break;
                    case 'units':
                        this.units = await this.getUnitsListFromDatabase();
                        this.saveJSONToLocalStorage('units', this.units);
                        break;
                    case 'deposits':
                        this.deposits = await this.getDepositsListFromDatabase();
                        let depositList = this.getDepositsFromLocalStorage();
                        this.saveJSONToLocalStorage('deposits', this.deposits);
                        this.saveJSONToLocalStorage('depositlist', depositList);
                        break;
                }

                this.saveJSONToLocalStorage('checksums', this.checksums);
            } else {
                // Daten aktuell, können aus Speicher geladen werden
                //console.log(`Checksums equal, use ${identifier} from cache.`)
                switch (identifier) {
                    case 'vgProducts':
                        this.vgProducts = this.getJSONFromLocalStorage('productList');
                        break;
                    case 'manufacturers':
                        this.manufacturers = this.getJSONFromLocalStorage('manufacturers');
                        break;
                    case 'countries':
                        this.countries = this.getJSONFromLocalStorage('countries');
                        break;
                    case 'units':
                        this.units = this.getJSONFromLocalStorage('units');
                        break;
                    case 'deposits':
                        this.deposits = this.getJSONFromLocalStorage('deposits');
                        break;
                }
            }
        }

        // Sicherheitsprüfung, falls hinterlegte Daten fehlerhaft
        while (
            this.vgProducts.length === 0 ||
            this.manufacturers.length === 0 ||
            this.countries.length === 0 ||
            this.units.length === 0 ||
            this.deposits.length === 0
        ) {
            if (this.vgProducts.length === 0) {
                this.vgProducts = await this.getProductListFromDatabase();
                this.saveJSONToLocalStorage('productList', this.vgProducts);
            } else if (this.manufacturers.length === 0) {
                this.manufacturers = await this.getManufacturersListFromDatabase();
                this.saveJSONToLocalStorage('manufacturers', this.manufacturers);
            } else if (this.countries.length === 0) {
                this.countries = await this.getCountriesListFromDatabase();
                this.saveJSONToLocalStorage('countries', this.countries);
            } else if (this.units.length === 0) {
                this.units = await this.getUnitsListFromDatabase();
                this.saveJSONToLocalStorage('units', this.units);
            } else if (this.deposits.length === 0) {
                this.deposits = await this.getDepositsListFromDatabase();
                this.saveJSONToLocalStorage('deposits', this.deposits);
            }
        }
        this.userProductData = this.getJSONFromLocalStorage('userProducts', {});
    }

    fetchDataFromLocalStorage() {
        this.vgProducts = this.getJSONFromLocalStorage('productList');
        this.manufacturers = this.getJSONFromLocalStorage('manufacturers');
        this.countries = this.getJSONFromLocalStorage('countries');
        this.units = this.getJSONFromLocalStorage('units');
        this.deposits = this.getJSONFromLocalStorage('deposits');
        this.userProductData = this.getJSONFromLocalStorage('userProducts', {});
        console.log('No connection - fetched data from offline storage.')
    }

    getJSONFromLocalStorage = (key: string, _default?: any) => {
        let storedData = localStorage.getItem(key);
        if (storedData === null) {
            if (_default === undefined) {
                return [];
            } else {
                return _default;
            }
        } else {
            return JSON.parse(storedData);
        }
    };

    saveJSONToLocalStorage = (
        key: string,
        data:
            | IProductFromDB[]
            | IManufacturerFromDB[]
            | IUnitFromDB[]
            | ICountryFromDB[]
            | IDepositFromDB[]
            | IChecklist //checklist
            | IDepositList // depositList
            | { [key: number]: IProductFromDB }
            | string[] //checklistOrder
            | IChecksums
    ) => {
        localStorage.setItem(key, JSON.stringify(data));
    };

    // Ruft komplette Produktliste von der DB ab
    async getProductListFromDatabase() {
        let productList: IProductFromDB[] = [];
        await fetch('https://app.vg-dresden.de/php/getProductList.php')
            .then(response => response.json())
            .then(data => {
                productList = data;
            })
            .catch(error => console.log(error));
        return productList;
    }

    // Ruft komplette Herstellerliste von der DB ab
    async getManufacturersListFromDatabase() {
        let manufacturersList: IManufacturerFromDB[] = [];
        await fetch('https://app.vg-dresden.de/php/getManufacturersList.php')
            .then(response => response.json())
            .then(data => {
                manufacturersList = data;
            })
            .catch(error => console.log(error));
        return manufacturersList;
    }

    // Ruft komplette Länderliste von der DB ab
    async getCountriesListFromDatabase() {
        let countriesList: ICountryFromDB[] = [];
        await fetch('https://app.vg-dresden.de/php/getCountriesList.php')
            .then(response => response.json())
            .then(data => {
                countriesList = data;
            })
            .catch(error => console.log(error));
        return countriesList;
    }

    // Ruft komplette Einheitenliste von der DB ab
    async getUnitsListFromDatabase() {
        let unitsList: IUnitFromDB[] = [];
        await fetch('https://app.vg-dresden.de/php/getUnitsList.php')
            .then(response => response.json())
            .then(data => {
                unitsList = data;
            })
            .catch(error => console.log(error));
        return unitsList;
    }

    // Ruft komplette Leergutliste von der DB ab
    async getDepositsListFromDatabase() {
        let depositsList: IDepositFromDB[] = [];
        await fetch('https://app.vg-dresden.de/php/getDepositsList.php')
            .then(response => response.json())
            .then(data => {
                depositsList = data;
            })
            .catch(error => console.log(error));
        return depositsList;
    }

    // Ruft Prüfsumme von der Datenbank ab
    async getChecksums() {
        let checksums = {
            manufacturers: undefined,
            countries: undefined,
            vgProducts: undefined,
            units: undefined,
            deposits: undefined
        };
        await fetch('https://app.vg-dresden.de/php/getChecksums.php')
            .then(response => response.json())
            .then(data => {
                for (let checksum of data) {
                    if (checksum.table.includes('manufacturers')) {
                        checksums.manufacturers = checksum.checksum;
                    } else if (checksum.table.includes('countries')) {
                        checksums.countries = checksum.checksum;
                    } else if (checksum.table.includes('vg-products')) {
                        checksums.vgProducts = checksum.checksum;
                    } else if (checksum.table.includes('units')) {
                        checksums.units = checksum.checksum;
                    } else if (checksum.table.includes('deposit')) {
                        checksums.deposits = checksum.checksum;
                    }
                }
            });
        //console.log('Retrieved checksums')
        return checksums;
    }

    getNextId = (): number => {
        const checklist = this.getJSONFromLocalStorage('checklist', {});
        let maxId = 0;
        for (let key in checklist) {
            if (maxId < Number(checklist[key].id)) {
                maxId = Number(checklist[key].id);
            }
        }
        return maxId + 1;
    };
})();
