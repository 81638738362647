import React from 'react';
import classes from './CheckoutDeposit.module.scss';
import CheckoutDepositListItem from './CheckoutDepositListItem/CheckoutDepositListItem';
import { IDepositList } from '../../shared/interfaces';

export interface IProps {
    deposit: IDepositList;
}

const CheckoutDeposit = (props: IProps) => {
    let depositList = [];
    for (let key in props.deposit) {
        const itemInfo = props.deposit[key];
        if (itemInfo.value !== 0) {
            depositList.push(
                <CheckoutDepositListItem key={itemInfo.vgNum} depositInfo={itemInfo} />
            );
        }
    }
    return (
        <div className={classes.checkoutDeposit}>
            <h3>Leergut</h3>
            <ul className={classes.checkoutDepositList}>{depositList}</ul>
        </div>
    );
};

export default CheckoutDeposit;
