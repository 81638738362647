import React, {Component} from 'react';
import classes from './DepositPage.module.scss';
import DepositListItem from '../../components/DepositListItem/DepositListItem';
import {IDepositList} from '../../shared/interfaces';
import {DataManager} from '../../shared/DataManager';
import {RouteComponentProps} from 'react-router';
import ClearListHandler from '../ShoppingListPage/ClearListHandler/ClearListHandler';
import {ViewContext} from '../../context/ViewContext';
import {EventManager} from "../../shared/EventManager";

interface IState {
    deposit: IDepositList;
    clearList: boolean;
}

class DepositPage extends Component<RouteComponentProps, IState> {
    state: IState = {
        deposit: {},
        clearList: false
    };
    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    clearList = () => {
        this.saveDepositToLocalStorage()
        this.setState({clearList: true})
    }

    componentDidMount() {
        this.context.actions.setToolbarTitle('Leergut');
        this.context.actions.setMenuItems([{action: 'Liste leeren', callback: this.clearList}])
        window.addEventListener('beforeunload', this.saveDepositToLocalStorage);
        window.addEventListener('error', this.removeEventListener);
        EventManager.subscribeToEvent('updatedUserListsData', this._onLocalStorageUpdated)
        this.syncStateWithLocalStorage();
    }

    componentWillUnmount() {
        this.context.actions.setMenuItems([])
        window.removeEventListener('beforeunload', this.saveDepositToLocalStorage);
        EventManager.unsubscribeFromEvent('updatedUserListsData', this._onLocalStorageUpdated)
        this.saveDepositToLocalStorage();
    }

    _onLocalStorageUpdated = () => {
        this.syncStateWithLocalStorage()
    }

    removeEventListener = () => {
        window.removeEventListener('beforeunload', this.saveDepositToLocalStorage);
    };

    saveDepositToLocalStorage = () => {
        DataManager.saveJSONToLocalStorage('depositlist', this.state.deposit);
    };

    syncStateWithLocalStorage = () => {
        const storedDeposit = localStorage.getItem('depositlist');

        if (!storedDeposit || storedDeposit === '{}') {
            const depositlist = DataManager.getDepositsFromLocalStorage();
            this.setState({deposit: depositlist});
        } else {
            this.setState({deposit: JSON.parse(storedDeposit)});
        }
    };

    changeValueHandler = (id: string, value: number) => {
        this.setState(previousState => {
            const updatedDeposit = { ...previousState.deposit };
            const updatedDepositType = { ...previousState.deposit[id] };
            updatedDepositType.value = value;
            updatedDeposit[id] = updatedDepositType;
            return { deposit: updatedDeposit };
        });
    };

    increaseValueHandler = (id: string) => {
        this.setState(previousState => {
            const updatedDeposit = { ...previousState.deposit };
            const updatedDepositType = { ...previousState.deposit[id] };
            updatedDepositType.value += 1;
            updatedDeposit[id] = updatedDepositType;
            return { deposit: updatedDeposit };
        });
    };

    decreaseValueHandler = (id: string) => {
        this.setState(previousState => {
            const updatedDeposit = { ...previousState.deposit };
            const updatedDepositType = { ...previousState.deposit[id] };
            if (updatedDepositType.value > 0) {
                updatedDepositType.value -= 1;
            }
            updatedDeposit[id] = updatedDepositType;
            return { deposit: updatedDeposit };
        });
    };

    cancelClearListHandler = () => {
        this.setState({ clearList: false });
    };

    render() {
        let depositArray = [];
        for (let key in this.state.deposit) {
            depositArray.push(
                <DepositListItem
                    type={this.state.deposit[key]}
                    key={key}
                    id={key}
                    change={this.changeValueHandler}
                    increase={this.increaseValueHandler}
                    decrease={this.decreaseValueHandler}
                />
            );
        }
        return (
            <div className={classes.depositPage}>
                <ClearListHandler
                    initDelete={this.state.clearList}
                    onCloseDialog={this.cancelClearListHandler}
                />
                {depositArray}
            </div>
        );
    }
}

export default DepositPage;
