import React, { MouseEvent } from 'react';
import classes from './CountryInfo.module.scss';

export interface IProps {
    country: string;
    clicked: (event: MouseEvent) => void;
}

const CountryInfo = (props: IProps) => {
    return (
        <div className={classes.countryInfo} onClick={props.clicked}>
            <div className={classes.flag}>{props.country}</div>
        </div>
    );
};

export default CountryInfo;
