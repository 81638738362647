import React, { ChangeEvent, Component } from 'react';
import classes from './FeedbackPage.module.scss';
import Button from '../../components/UI/Button/Button';
import { DataManager } from '../../shared/DataManager';
import Modal from '../../components/UI/Modal/Modal';
import { ViewContext } from '../../context/ViewContext';

interface IState {
    feedbackTitle: string;
    feedbackMail: string;
    feedbackContent: string;
    feedbackSend?: boolean;
    isInitialized: boolean;
}

class FeedbackPage extends Component<{}, IState> {
    public readonly state: IState = {
        feedbackTitle: '',
        feedbackMail: '',
        feedbackContent: '',
        feedbackSend: undefined,
        isInitialized: false
    };

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    componentDidMount() {
        this.context.actions.setToolbarTitle('Feedback');
        const storedFeedback = DataManager.getLastFeedback();
        window.addEventListener('beforeunload', this._storeFeedback);
        this.setState({
            feedbackTitle: storedFeedback.title,
            feedbackMail: storedFeedback.mail,
            feedbackContent: storedFeedback.content,
            isInitialized: true
        });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this._storeFeedback);
        this._storeFeedback();
    }

    _storeFeedback = () => {
        DataManager.saveFeedback(
            this.state.feedbackTitle,
            this.state.feedbackMail,
            this.state.feedbackContent
        );
    };

    _onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ feedbackTitle: event.currentTarget.value });
    };

    _onMailChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ feedbackMail: event.currentTarget.value });
    };

    _onTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ feedbackContent: event.currentTarget.value });
    };

    _doSubmit = async () => {
        let feedbackSend = await DataManager.sendFeedback(
            this.state.feedbackTitle,
            this.state.feedbackMail,
            this.state.feedbackContent
        );
        this.setState({ feedbackSend });
    };

    _onModalClosed = () => {
        if (this.state.feedbackSend) {
            this.setState({ feedbackSend: undefined, feedbackTitle: '', feedbackContent: '' });
        } else {
            this.setState({ feedbackSend: undefined });
        }
    };

    render() {
        return (
            <div className={classes.feedbackPage}>
                <input
                    type="text"
                    className={classes.feedbackTitle}
                    placeholder="Betreff"
                    autoFocus={this.state.isInitialized && this.state.feedbackTitle === ''}
                    value={this.state.feedbackTitle}
                    onChange={this._onTitleChange}
                />
                <input
                    type="text"
                    className={classes.feedbackMail}
                    placeholder="E-Mail (Optional)"
                    value={this.state.feedbackMail}
                    onChange={this._onMailChange}
                />
                <textarea
                    placeholder="Dein Feedback"
                    autoFocus={this.state.isInitialized && this.state.feedbackTitle !== ''}
                    value={this.state.feedbackContent}
                    onChange={this._onTextChange}
                    className={classes.feedbackText}
                />
                <Button
                    buttonType="Primary"
                    disabled={!this.state.feedbackTitle || !this.state.feedbackContent}
                    clicked={this._doSubmit}
                >
                    Feedback absenden
                </Button>
                <Modal show={this.state.feedbackSend === true} modalClosed={this._onModalClosed}>
                    <h3>Feedback gesendet!</h3>
                    <div>Vielen Dank! Das Feedback wurde übermittelt!</div>
                    <Button buttonType="Primary" clicked={this._onModalClosed}>
                        Okay
                    </Button>
                </Modal>
                <Modal show={this.state.feedbackSend === false} modalClosed={this._onModalClosed}>
                    <h3>Upps, da ist was schief gegangen :(</h3>
                    <div>
                        Bitte versuch es später noch einmal.
                        <br />
                        <br />
                        Keine Sorge: Dein Feedback wird so lange auf Deinem Gerät gespeichert.
                    </div>
                    <div>
                        <Button buttonType="Primary" clicked={this._onModalClosed}>
                            Okay
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default FeedbackPage;
