const MIN_SWIPE_LENGTH = 30;

export let GestureManager = new (class {
    dragState: { startX?: number; startY?: number; startTime?: number } = {
        startX: undefined,
        startY: undefined,
        startTime: undefined
    };
    gestures = {
        longPress: [],
        whileLongPress: [],
        swipeLeft: [],
        swipeRight: [],
        dragEnd: []
    };
    longPressTimer?: number;
    highlightLongPressTimer?: number;

    initialize = () => {
        window.addEventListener('mousedown', this.onMouseDown);
        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('touchstart', this.onTouchStart);
        window.addEventListener('touchend', this.onTouchEnd);
        window.addEventListener('touchcancel', this.onTouchEnd);
        window.addEventListener('touchmove', this.onTouchMove);
    };

    unInitialize = () => {
        window.removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('mouseup', this.onMouseUp);
        window.removeEventListener('touchstart', this.onTouchStart);
        window.removeEventListener('touchend', this.onTouchEnd);
        window.removeEventListener('touchcancel', this.onTouchEnd);
        window.removeEventListener('touchmove', this.onTouchMove);
    };

    registerGesture = (gesture: string, callback: (offsetX: number, event: TouchEvent) => void) => {
        //@ts-ignore
        if (callback in this.gestures[gesture]) {
            return;
        }
        //@ts-ignore
        this.gestures[gesture].push(callback);
    };

    unregisterGesture = (
        gesture: string,
        callback: (offset: number, event: TouchEvent) => void
    ) => {
        //@ts-ignore
        if (this.gestures[gesture].indexOf(callback) !== -1) {
            //@ts-ignore
            this.gestures[gesture].pop(callback);
        }
    };

    reset = () => {
        this.dragState = {
            startX: undefined,
            startY: undefined,
            startTime: undefined
        };
        this.gestures = {
            longPress: [],
            whileLongPress: [],
            swipeLeft: [],
            swipeRight: [],
            dragEnd: []
        };
        this.longPressTimer = undefined;
        this.highlightLongPressTimer = undefined;
    };

    onMouseDown = (event: MouseEvent) => {
        // Startet Timer für longPress-Geste opt. Feedback
        this.highlightLongPressTimer = window.setTimeout(() => {
            this.executeCallback(this.gestures.whileLongPress);
        }, 300);
        this.longPressTimer = window.setTimeout(() => {
            event.preventDefault();
            this.executeCallback(this.gestures.longPress, event);
        }, 1000);

        // Erfasst x-Koordinate für Drag-Geste
        this.onDragStart(event.clientX, event.clientY);
    };

    onTouchStart = (event: TouchEvent) => {
        // Startet Timer für longPress-Geste u. opt. Feedback
        if (event.touches.length <= 1) {  // < 1: Tests
            // Nicht aktivieren für Touches mit mehreren Fingern
            this.highlightLongPressTimer = window.setTimeout(() => {
                this.executeCallback(this.gestures.whileLongPress);
            }, 100);

            this.longPressTimer = window.setTimeout(() => {
                this.executeCallback(this.gestures.longPress, event);
            }, 1000);
        }
        let touchChanges = event.changedTouches[0];
        this.onDragStart(touchChanges.clientX, touchChanges.clientY);
    };

    onMouseUp = () => {
        clearTimeout(this.longPressTimer);
    };

    onTouchEnd = (event: TouchEvent) => {
        clearTimeout(this.longPressTimer);
        let touchChanges = event.changedTouches[0];
        this.onDragEnd(touchChanges.clientX, event);
    };

    onTouchMove = (event: TouchEvent) => {
        // Verhindert LongPress-Event während Swipe u. bei Wisch mit mehreren Fingern
        clearTimeout(this.longPressTimer);
        if (event.touches.length > 1) {
            event.preventDefault();
            return;
        }

        let touchChanges = event.changedTouches[0];
        this.onDrag(touchChanges.clientX, touchChanges.clientY, event);
    };

    onDragStart = (x: number, y: number) => {
        this.dragState = {
            startX: x,
            startY: y
        };
    };

    onDragEnd = (x: number, event: TouchEvent) => {
        if (this.dragState.startX === undefined) {
            return;
        }
        let offsetX = this.dragState.startX - x;

        this.executeCallback(this.gestures.dragEnd, offsetX, event);
    };

    onDrag = (x: number, y: number, event: TouchEvent) => {
        let offsetX = this.dragState.startX! - x;
        let offsetY = this.dragState.startY! - y;

        // Swipe erst ausgeführt, wenn Geste weit genug geht
        if (Math.abs(offsetX) < MIN_SWIPE_LENGTH) {
            return;
        }

        if (Math.abs(offsetX) < 4 * Math.abs(offsetY)) {
            // wenn Geste diagonal bzw. schräg, wird Startpunkt zurückgesetzt und neu geprüft
            this.onDragStart(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
            return;
        }

        // Wenn Bedingungen erfüllt, führe Callback aus
        if (offsetX > 0) {
            this.executeCallback(this.gestures.swipeLeft, offsetX - MIN_SWIPE_LENGTH, event);
        } else if (offsetX < 0) {
            this.executeCallback(this.gestures.swipeRight, offsetX + MIN_SWIPE_LENGTH, event);
        }
    };

    executeCallback = (callbacks: ((...args: any) => void)[], ...args: any) => {
        for (let callback of callbacks) {
            callback(...args);
        }
    };
})();
