import React, { Component, FormEvent, ChangeEvent, MouseEvent } from 'react';
import classes from './InputLine.module.scss';
import Button from '../UI/Button/Button';
import { ReactComponent as Barcode } from '../../img/icons/barcode.svg';
import { ReactComponent as NumberInputIcon } from '../../img/icons/numberInput.svg';
import { ReactComponent as TextInputIcon } from '../../img/icons/textInput.svg';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReactComponent as PenIcon } from '../../img/icons/pen.svg';
import { ReactComponent as ClearIcon } from '../../img/icons/clear.svg';
import {SettingsManager} from "../../shared/SettingsManager";

interface IProps extends RouteComponentProps {
    addEntry: (input: string) => void;
    editEntry: (title: string) => void;
}
interface IState {
    inputValue: string;
    showButtons: boolean;
    editEntry: boolean;
    numberInput: boolean;
}

class InputLine extends Component<IProps, IState> {
    state = {
        inputValue: '',
        showButtons: false,
        editEntry: true,
        numberInput: false
    };

    componentDidUpdate() {
        if (!this.state.showButtons && this.state.inputValue.length > 0) {
            this.setState({ showButtons: true });
        } else if (this.state.showButtons && this.state.inputValue.length === 0) {
            this.setState({ showButtons: false });
        }
    }

    addEntryHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let inputValue = (event.currentTarget.elements.namedItem(
            'add'
        ) as HTMLInputElement).value.trim();

        if (inputValue.length !== 0) {
            this.props.addEntry(inputValue);
        }

        this.resetInputHandler();
    };

    initEditEntryHandler = (event: MouseEvent) => {
        event.preventDefault();
        // Eingabefeld leeren. Fokus nicht setzen, da sich im Vordergrund ein Dialog befindet!
        this.setState({ inputValue: '' });
        this.props.editEntry(this.state.inputValue);
    };

    inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
        this.setState(() => {
            return { inputValue };
        });
    };

    // Setzt Eingabe zurück und fokussiert Input erneut
    resetInputHandler = (event?: MouseEvent) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({ inputValue: '' });
        try {
            document.getElementById('add')!.focus();
        } catch {
            // In Tests
        }
    };

    // Toggelt Icons sowie Input-Type im Header zwischen "number" und "text"
    changeInputModeHandler = () => {
        setTimeout(() => {
            document.getElementById('add')!.focus();
        }, 100);

        this.setState(previousState => {
            return { numberInput: !previousState.numberInput };
        });
    };

    startScanHandler = () => {
        this.props.history.push('/scanner');
    };

    render() {
        const autoComplete = SettingsManager.getSettings().autoComplete ? 'on' : 'off';
        return (
            <div className={classes.inputLine}>
                <form onSubmit={this.addEntryHandler}>
                    <input
                        className={classes.input}
                        id="add"
                        type={this.state.numberInput ? 'number' : 'text'}
                        placeholder={
                            this.state.numberInput ? 'VG-Nummer hinzufügen' : 'Artikel hinzufügen'
                        }
                        onChange={this.inputChangeHandler}
                        value={this.state.inputValue}
                        autoComplete={autoComplete}
                    />
                    {this.state.showButtons ? (
                        <div className={classes.buttons}>
                            <Button clicked={this.initEditEntryHandler} buttonType="Icon">
                                <PenIcon style={{height: "1.5rem", fill: 'var(--light-grey)', stroke: 'var(--light-grey)'}} />
                            </Button>
                            <Button clicked={this.resetInputHandler} buttonType="Icon">
                                <ClearIcon style={{height: "1.5rem", fill: 'var(--light-grey)', stroke: 'var(--light-grey)'}} />
                            </Button>
                        </div>
                    ) : (
                        <div className={classes.buttons}>
                            {this.state.numberInput ? (
                                <Button clicked={this.changeInputModeHandler} buttonType="Icon">
                                    <TextInputIcon />
                                </Button>
                            ) : (
                                <Button clicked={this.changeInputModeHandler} buttonType="Icon">
                                    <NumberInputIcon />
                                </Button>
                            )}
                            <Button clicked={this.startScanHandler} buttonType="Icon">
                                <Barcode />
                            </Button>
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

export default withRouter(InputLine);
