import React, { Component, ReactNode } from 'react';
import classes from './Modal.module.scss';
import Backdrop from '../Backdrop/Backdrop';

interface IProps {
    show: boolean;
    modalClosed: () => void;
    children: ReactNode;
}

class Modal extends Component<IProps> {
    shouldComponentUpdate(nextProps: IProps) {
        return (
            nextProps.show !== this.props.show ||
            (this.props.show && nextProps.children !== this.props.children)
        );
    }

    render() {
        let modalClasses = [classes.Modal, this.props.show ? classes.Show : null]
            .filter(Boolean)
            .join(' ');
        if (!this.props.show) {
            return null;
        }
        return (
            <div className={classes.modalBackground}>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
                <div className={modalClasses}>{this.props.children}</div>
            </div>
        );
    }
}

export default Modal;
