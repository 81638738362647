import React from 'react';
import classes from './ProductInfo.module.scss';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import { DataManager } from '../../shared/DataManager';

import { IProductFromDB } from '../../shared/interfaces';

interface IProps {
    vgNum: number;
    hideInfo: () => void;
}

const ProductInfo = (props: IProps) => {
    // benötigte Daten aus div. Datenbanken mit VG-Nummer abgerufen
    let info: IProductFromDB = DataManager.getProductByVgNum(props.vgNum);
    const originalInfo = DataManager.getProductFromDb(props.vgNum);
    let manufacturer = DataManager.getManufacturerById(info.manufacturerID);
    let country = DataManager.getCountryById(info.countryID);
    let unit = DataManager.getUnitById(info.unitID);
    let checklist = DataManager.getJSONFromLocalStorage('checklist', {});

    // Anlagedatum erstellen und in lesbares Format übertragen
    let dateOfEntry;
    const articleTitle = originalInfo ? originalInfo.title : info.title;
    const dateFormatOptions = {
        weekday: 'long' as 'long',
        year: 'numeric' as 'numeric',
        month: 'long' as 'long',
        day: 'numeric' as 'numeric',
        hour: '2-digit' as '2-digit',
        minute: '2-digit' as '2-digit',
        second: 'numeric' as 'numeric'
    };
    for (let key in checklist) {
        if (checklist[key].vgNum === props.vgNum) {
            dateOfEntry = new Date(checklist[key].date).toLocaleDateString(
                'de-DE',
                dateFormatOptions
            );
        }
    }

    return (
        <Modal show={true} modalClosed={props.hideInfo}>
            <div className={classes.productInfo}>
                <h3>Produkt-Informationen</h3>
                <div className={classes.infoContainer}>
                    <table>
                        <tbody>
                        <tr>
                            <th>VG-Nummer:</th>
                            <th>{info.vgNum}</th>
                        </tr>
                        <tr>
                            <td>Bezeichnung:</td>
                            <td>{articleTitle}</td>
                        </tr>
                        <tr>
                            <td>Hersteller:</td>
                            <td>{manufacturer}</td>
                        </tr>
                        <tr>
                            <td>Land:</td>
                            <td>{country}</td>
                        </tr>
                        <tr>
                            <td>RegionalProdukt:</td>
                            <td>{info.isRegional ? 'Ja' : 'Nein'}</td>
                        </tr>
                        <tr>
                            <td>Verkaufseinheit:</td>
                            <td>{unit}</td>
                        </tr>
                        <tr>
                            <td>Aufnahmezeit:</td>
                            <td>{dateOfEntry}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <Button clicked={props.hideInfo} buttonType="Primary">
                    Ok
                </Button>
            </div>
        </Modal>
    );
};

export default ProductInfo;
