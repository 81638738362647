import React, { Component, MouseEvent } from 'react';
import classes from './Popup.module.scss';
import Button from '../Button/Button';
import {ViewContext} from "../../../context/ViewContext";

interface IProps {
    show: boolean;
    // lastAdded: { title: string; id: number };
    message?: string;
    buttonText?: string;
    buttonClicked?: (event: MouseEvent) => void;
}

class Popup extends Component<IProps> {
    shouldComponentUpdate(nextProps: IProps) {
        return nextProps.show !== this.props.show || (this.props.show && nextProps !== this.props);
    }

    render() {
        if (!this.props.show) {return null;}
        let popupClasses = [classes.popup, this.props.show ? classes.show : null]
            .filter(Boolean)
            .join(' ');
        return (
            <div className={classes.popupBackground}>
                <div className={popupClasses}>
                    {this.props.message ? <p>{this.props.message}</p> : null}

                    {this.props.buttonClicked ? (
                        <Button buttonType="Primary" clicked={this.props.buttonClicked}>
                            {this.props.buttonText}
                        </Button>
                    ) : null}
                </div>
            </div>
        );
    }
}

export class MainPopup extends Component {
    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    render() {
        return <Popup show={this.context.state.popupShown}
                      message={this.context.state.popupMessage}
                      buttonText={this.context.state.popupAction}
                      buttonClicked={this.context.state.popupCallback}
                      />
    }
}

export default Popup;
