import React, { Component } from 'react';
import classes from './CheckoutPage.module.scss';
import { sortChecklist } from '../../shared/utilities';
import ClearListHandler from '../ShoppingListPage/ClearListHandler/ClearListHandler';

import CheckoutList from '../../components/CheckoutList/CheckoutList';
import CheckoutDeposit from '../../components/CheckoutDeposit/CheckoutDeposit';
import { IChecklist, IDepositList } from '../../shared/interfaces';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataManager } from '../../shared/DataManager';
import { ViewContext } from '../../context/ViewContext';
import {EventManager} from "../../shared/EventManager";
import CheckoutBakeryList from "../../components/CheckoutList/CheckoutBakeryList";

export interface IState {
    checklist: IChecklist;
    deposit: IDepositList;
    loading: boolean;
    clearList: boolean;
}

class CheckoutPage extends Component<RouteComponentProps, IState> {
    state: IState = {
        checklist: {},
        deposit: {},
        loading: false,
        clearList: false
    };

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    componentDidMount() {
        this.context.actions.setToolbarTitle('Kasse');
        this.context.actions.setMenuItems([{action: 'Liste leeren', callback: this.clearList}])
        EventManager.subscribeToEvent('updatedUserListsData', this._onUpdatedListData)
        this.syncStateWithLocalStorage();
    }


    componentWillUnmount() {
        this.context.actions.setMenuItems([])
        EventManager.unsubscribeFromEvent('updatedUserListsData', this._onUpdatedListData)
    }

    clearList = () => {
        this.setState({clearList: true})
    }

    syncStateWithLocalStorage = () => {
        const storedChecklist = DataManager.getJSONFromLocalStorage('checklist', {});
        const alphabeticallySortedList = sortChecklist(storedChecklist);
        let storedDeposit = DataManager.getJSONFromLocalStorage('depositlist', []);
        this.setState({ deposit: storedDeposit, checklist: alphabeticallySortedList });
    };

    cancelClearListHandler = () => {
        this.setState({ clearList: false });
    };

    render() {
        let depositListNeeded = false;
        let bakeryListNeeded = false;
        for (let key in this.state.deposit) {
            const itemInfo = this.state.deposit[key];
            if (itemInfo.value > 0) {
                depositListNeeded = true;
                break;
            }
        }
        for (let key in this.state.checklist) {
            const itemInfo = this.state.checklist[key];
            if (itemInfo.vgNum && itemInfo.vgNum < 1000 && itemInfo.checked) {
                bakeryListNeeded = true;
                break;
            }
        }
        if (Object.keys(this.state.checklist).length === 0 && !depositListNeeded) {
            return <div className={classes.CheckoutPage}><div className={classes.NoEntrySpan}>
                Lege Produkte mit einer VG-Nummer in den Einkaufswagen, um sie hier anzuzeigen.
            </div> </div>
        }
        return (
            <div className={classes.CheckoutPage}>
                <ClearListHandler
                    initDelete={this.state.clearList}
                    onCloseDialog={this.cancelClearListHandler}
                />
                <CheckoutList list={this.state.checklist} />
                {bakeryListNeeded ? <CheckoutBakeryList list={this.state.checklist} /> : null}
                {depositListNeeded ? <CheckoutDeposit deposit={this.state.deposit} /> : null}
            </div>
        );
    }

    _onUpdatedListData = () => {
        this.syncStateWithLocalStorage()
    }
}

export default withRouter(CheckoutPage);
