import { IChecklist, IProduct } from './interfaces';

export function getSearchParams(searchProps: string) {
    const query = new URLSearchParams(searchProps);
    const hash = window!.top!.location.hash.substr(1);
    return {
        add: Number(query.get('add')),  // Element hinzufügen
        clear: Boolean(query.get('clear')),  // Liste leeren
        drag: Boolean(query.get('drag')),  // Drag-Modus ein
        share: Boolean(query.get('share')),  // Teilen-Dialog anzeigen
        shareId: query.get('shareId'),  // Geteilte Liste importieren
        hash: hash  // Alles hinter dem # wird nur lokal im Browser ausgewertet. Eignet sich gut
                    // zum Speichern von Schlüsseln in Links
    };
}

export function sortChecklist(checklist: IChecklist) {
    let checklistArray = [];
    for (let key in checklist) {
        checklistArray.push(checklist[key]);
    }

    checklistArray.sort(compareObjects);
    let newChecklist: IChecklist = {};
    checklistArray.forEach((entry, index) => {
        newChecklist[index] = entry;
        newChecklist[index].order = index;
    });

    return newChecklist;
}

function compareObjects(a: IProduct, b: IProduct) {
    if (a.title < b.title) {
        return -1;
    } else if (a.title > b.title) {
        return 1;
    } else {
        return 0;
    }
}

export function getRandomPhrase(length: number) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.random()*chars.length);
    }
    return result
}
