export interface Events {
    updatedUserListsData: undefined
}

export let EventManager = new (class {
    private _events: {[key in keyof Events]: ((data: Events[key]) => void)[]};

    constructor() {
        // @ts-ignore
        this._events = {}
    }

    subscribeToEvent<T extends keyof Events>(event: T, callback: (data: Events[T]) => void) {
        if (!this._events[event]) {
            this._events[event] = []
        }
        this._events[event].push(callback);
    }

    unsubscribeFromEvent<T extends keyof Events>(event: T, callback: (data: Events[T]) => void) {
        // @ts-ignore
        this._events = this._events[event].filter((value) => value === callback)
    }

    emitEvent<T extends keyof Events>(event: T, data: Events[T]) {
        if (this._events[event]) {
            this._events[event].forEach((item) => item(data))
        }
    }
})();