import React from 'react';
import classes from './Setting.module.scss';
import Checkbox from "../../../components/Checkbox/Checkbox";

export interface IProps {
    title: string;
    checked: boolean;
    description: string;
    toggleCheck: () => void;
}

const Setting = (props: IProps) => {
    // Verhindert readOnly-Warnung für Checkbox
    function toggleCheckHandler() {
        props.toggleCheck()
    }

    return (
        <li className={classes.setting} onClick={props.toggleCheck}>
            <div className={classes.label}>
                <h4>{props.title}</h4>
                <p>{props.description}</p>
            </div>
            <Checkbox inline active={props.checked} onClick={toggleCheckHandler} />
        </li>
    );
};

export default Setting;
