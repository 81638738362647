import React from 'react';
import { IProduct } from '../../../shared/interfaces';
import classes from './CheckoutListItem.module.scss';

interface IProps {
    info: IProduct;
}

interface IState {
    checked: boolean
}

class ChecklistItem extends React.Component<IProps, IState> {
    public state: IState = {
        checked: false
    }

    toggleCheckState = () => {
        this.setState({checked: !this.state.checked})
    }

    render() {
        let itemStyle = classes.checkoutListItem;
        if (this.state.checked) {
            itemStyle += ` ${classes.checkoutListItemSharedDisabledItem}`
        }
        const has_pieces = ["g", "kg", "Liter"].includes(this.props.info.unit || "") || this.props.info.amount === 1
        return (
            <li className={itemStyle} onClick={this.toggleCheckState}>
                <div className={classes.itemInfo}>
                    <span className={classes.name}>{this.props.info.title}</span>
                    {this.props.info.amount && this.props.info.unit ? (
                        <span className={classes.amount}>
                        [{has_pieces ? `${this.props.info.amount} `: null}{this.props.info.unit}]
                    </span>
                    ) : null}
                </div>
                <div className={classes.vgNum}>
                    {has_pieces
                        ? null : <span className={classes.pieces}>{this.props.info.amount} &times; </span>}
                    {this.props.info.vgNum}</div>
            </li>
        );
    }
}

export default ChecklistItem;
