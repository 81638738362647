import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classes from './ShareListDialog.module.scss';

import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import {DataManager} from "../../../shared/DataManager";
import { BrowserQRCodeSvgWriter } from '@zxing/library';
import {ViewContext} from "../../../context/ViewContext";
import {IProduct} from "../../../shared/interfaces";
import {LoadingCircle} from '../../../components/UI/Spinner/Spinner';

interface IProps extends RouteComponentProps {
    onCloseDialog: () => void;
}

interface IState {
    shared: boolean;
    error: boolean;
    url: string | null;
}
/**
 * Ermöglicht es, die Liste zu teilen.
 */
class ShareListDialog extends PureComponent<IProps, IState> {
    public state: IState = {
        shared: false,
        url: null,
        error: false,
    };

    _shareListHandler = async () => {
        const shoppingList: {[key: number]: IProduct} = DataManager.getJSONFromLocalStorage('checklist')
        const shoppingListEntries: IProduct[] = []
        for (let key in shoppingList) {
            shoppingListEntries.push(shoppingList[key])
        }
        this.setState({ shared: true });
        const url = await DataManager.shareList(shoppingListEntries);
        setTimeout(() => this.setState({ url }), 700);
    };

    render() {
        return (
            <>
                <Modal
                    show={!this.state.shared}
                    modalClosed={this.props.onCloseDialog}
                >
                    <div>
                        <p>Einträge per Link teilen</p>
                        <div>
                            <Button buttonType="Primary" clicked={this._shareListHandler}>
                                Teilen
                            </Button>
                            <Button buttonType="Secondary" clicked={this.props.onCloseDialog}>
                                Abbrechen
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.shared} modalClosed={this.props.onCloseDialog}>
                    <div>
                        <DivUploading show={this.state.shared && !this.state.url}/>
                        <DivShared show={Boolean(this.state.shared && this.state.url)}
                                   url={this.state.url!}/>
                        <Button buttonType="Secondary" clicked={this.props.onCloseDialog}>
                            Ok
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

function DivUploading(props: {show: boolean})  {
    if (!props.show) {return null}
    return <div>
        <div className={classes.loadingSpinnerContainer}>
            <LoadingCircle/>
        </div>
        Uploading...
    </div>
}

class DivShared extends React.Component<{show: boolean, url: string}> {

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    _copyToClipboard = () => {
        navigator.clipboard.writeText(this.props.url);
        this.context.actions.showPopup('URL in Zwischenablage kopiert')
    }

    componentDidUpdate(prevProps: Readonly<{show: boolean; url: string}>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.url && !prevProps.url) {
            const codeWriter = new BrowserQRCodeSvgWriter();
            try {
                codeWriter.writeToDom('#barcode', this.props.url!, 250, 250);
            } catch {
                // Sicherheitshalber, für den Fall dass das div doch nicht (mehr) gerendert ist...
            }
        }
    }

    render() {
        if (!this.props.show) {
            return null
        }
        return <div onClick={this._copyToClipboard} className={classes.copyableContent}>
            <div id="barcode"/>
            <a href='#/' className={classes.shareUrl}>{this.props.url}</a>
            <i className={'far fa-copy ' + classes.icoCopy}/>
        </div>
    }
}

export default withRouter(ShareListDialog);
