import classes from '../../pages/ShoppingListPage/ShoppingListPage.module.scss';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import React from 'react';

interface IProps {
    vgNum: number;
    onCancel: () => void;
    onOk: () => void;
}

export function ConfirmCreateNewEntryModal(props: IProps) {
    return (
        <Modal show={true} modalClosed={props.onCancel}>
            <div className={classes.productInfo}>
                <h3>Eintrag erstellen?</h3>
                <p>
                    Es wurde kein Artikel mit der Nummer {props.vgNum} gefunden. Neuen Eintrag
                    erstellen?
                </p>
                <Button clicked={props.onOk} buttonType="Primary">
                    Ja
                </Button>
                <Button clicked={props.onCancel} buttonType="Primary">
                    Nein
                </Button>
            </div>
        </Modal>
    );
}
